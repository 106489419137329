import { useContext, useState } from 'react';
import { useParams, Link, useRouteLoaderData, useHref } from 'react-router-dom';
import { useGlobalState } from "./State";
import { ModalContext } from '../context/ModalProvider';

export default function QuoteForm({ product, IKQaction = 1 }) {
    const [ZipCode] = useGlobalState("ZipCode");
    const [DealerNumber] = useGlobalState("DealerNumber");

    const modal = useContext(ModalContext);

    //todo delete - const [searchParams, setSearchParams] = useSearchParams();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        comments: '',
        city: '',
        state: '',
        zip: ZipCode,
        selectedDealer: (IKQaction === 2) ? '' : DealerNumber, // IKQ workaround, don't send dealer number on email action
        overSixteen: 'False',
        optInMarketing: 'True'
    });
    const [validationErrors, setValidationErrors] = useState({});
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handleCheckbox = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked ? 'True' : 'False' });
    }

    const validateUserData = () => {
        const errors = {};
        if (formData.overSixteen != 'True') {
            errors.overSixteen = 'Required';
        }
        if (!formData.email) {
            errors.email = 'Required';
        }
        else if (!formData.email.match(/\S+@\S+\.\S+/)) {
            errors.email = 'Invalid email address';
        }
        if (!formData.zip) {
            errors.zip = 'Required';
        }

        setValidationErrors(errors);
        console.log(errors);

        return (Object.keys(errors).length == 0);
    }

    async function validateAndSend(e)  {
        e.stopPropagation();
        if (!validateUserData()) return;

        modal.show(
            <>
                <div className="modal-header">
                    <h2 className="modal-title">Sending...</h2>
                </div>
            </>
        );

        let quoteId = await modal.sendToIKQ(IKQaction, formData);

        if (quoteId) {
            window.gtag('event', 'Quote Submitted', {
                'Model': product.Tractor.Name
            });

            modal.show(
                <>
                    <div className="modal-header">
                        <h2 className="modal-title">{IKQaction === 2 ? "Email Has Been Sent!" : "Quote Has Been Sent!"}</h2>
                    </div>
                    <div className="modal-body config-validation land-pride">
                        <span className="validation-headline center">{IKQaction === 2 ? "Your email has been sent to the user" : "Your quote has been sent to your selected Dealer"}</span>
                    </div>
                </>
            );
        }
        else {
            modal.show(
                <>
                    <div className="modal-header">
                        <h2 className="modal-title">{IKQaction === 2 ? "There was a Problem Sending Your Email" : "There was a Problem Submitting Your Quote"}</h2>
                    </div>
                    <div className="modal-body config-validation land-pride">
                        <span className="validation-headline center">{IKQaction === 2 ? "Something went wrong when sending your email, please try again." : "Something went wrong when submitting your quote, please try again."}</span>
                    </div>
                </>
            );
        }
    }

    return (
        <>
            <div id="build_details_container_send_quote" className="detailsContainerContent right-list sendquotePanel bottomBorder sendQuoteFormContainer">

                <input type="hidden" id="sendQuoteOptIn" name="sendQuoteOptIn" value="true" />
                <input type="hidden" id="sendQuoteAgeGate" name="sendQuoteAgeGate" value="false" />
                <div className="formContainer">
                    <h2>User Information</h2>
                    <div className="form-element-container">
                        <input type="text" id="sendQuoteFirstName" name="firstName" placeholder="First Name" value={formData['firstName']} onChange={handleChange} />
                    </div>
                    <div className="form-element-container">
                        <input type="text" id="sendQuoteLastName" name="lastName" placeholder="Last Name" value={formData['lastName']} onChange={handleChange} />
                    </div>
                    <div className="form-element-container">
                        <input type="email" className={validationErrors?.email ? 'error' : ''} id="sendQuoteEmail" name="email" placeholder="Email*" value={formData['email']} onChange={handleChange} />
                        {validationErrors?.email && (
                            <div className="validationMessage">{validationErrors.email}</div>    
                        )}
                    </div>
                    <div className="form-element-container">
                        <input type="tel" id="sendQuotePhone" name="phone" placeholder="Phone" value={formData['phone']} onChange={handleChange} />
                    </div>
                    <div className="form-element-container">
                        <input type="text" id="sendQuoteCity" name="city" placeholder="City" value={formData['city']} onChange={handleChange} />
                    </div>
                    <div className="form-element-container">
                        <input type="text" id="sendQuoteState" name="state" placeholder="State" value={formData['state']} onChange={handleChange} />
                    </div>
                    <div className="form-element-container">
                        <input type="text" className={validationErrors?.zip ? 'error' : ''} id="sendQuoteZip" name="zip" placeholder="Zip Code*" value={formData['zip']} onChange={handleChange} />
                        {validationErrors?.zip && (
                            <div className="validationMessage">{validationErrors.zip}</div>
                        )}
                    </div>
                    <div className="form-element-container full">
                        <textarea id="sendQuoteComments" name="comments" placeholder="Comments"></textarea>
                    </div>
                    <div className="form-element-container full">
                        <div className="form-element-checkbox-container" style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'flex-start'}}>
                            <input type="checkbox" name="optInMarketing" checked={formData.optInMarketing == 'True'} onChange={handleCheckbox} style={{ fontSize: '200%', width: '5%' }} />
                            <div className="checkbox-text" style={{ textAlign: 'left', width:' 90%' }}>
                                I would like to receive communications from Kubota Tractor Corporation and their affiliates.
                            </div>
                        </div>
                    </div>
                    <div className="form-element-container full" style={{ marginTop: '1rem' }}>
                        <div className="form-element-checkbox-container" style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'flex-start' }}>
                            <input type="checkbox" className={validationErrors?.overSixteen ? 'error' : ''} name="overSixteen" checked={formData.overSixteen == 'True'} onChange={handleCheckbox} style={{ fontSize: '200%', width: '5%' }} />
                            <div className="checkbox-text" style={{ textAlign: 'left', width: ' 90%' }}>
                                I am 16 or older.*
                                {validationErrors?.overSixteen && (
                                    <div className="validationMessage">{validationErrors.overSixteen}</div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="form-element-container full" style={{ marginTop: '1rem' }}>
                        <div className="disclaimer-text" style={{ textAlign: 'left', width: ' 90%' }}>
                            By clicking {IKQaction == 2 ? "Send" : "Submit"}, you agree to reading and accepting the <a href="https://www.kubotausa.com/terms-of-use" target="_blank">Terms of Use</a> and <a href="https://www.kubotausa.com/privacy-policy" target="_blank">Privacy Policies</a>
                        </div>
                    </div>
                    <button id="sendQuoteButton" className="send-quote-button orange-button" onClick={validateAndSend}>{IKQaction == 2 ? "Send Email" : "Submit"}</button>
                </div>
            </div>

            <Link id="build_details_container_next_button" className="nextButton detailsContainerContent wholegoodsPanel addonsPanel summaryPanel sendquotePanel button orange-button active" to={'summary' + window.location.search}>Back to Summary</Link>
        </>
    );
}