import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function AddOnList({ packages, selectedPackage, addOnGroups, selectedAddOns, tryAddOn, baseUrl }) {
    const [openGroups, setOpenGroups] = useState([]);

    if (!addOnGroups && (!packages || packages.length == 0)) {
        return null;
    }

    const groups = [];

    if (addOnGroups.OptionGroupList) {
        addOnGroups.OptionGroupList.forEach((group) => {
            const groupData = { id: group.Id, name: group.GroupName, addOns: [], open: openGroups.includes(group.Id) };
            group.AttachmentList.forEach((addOn) => {
                const activeAddOn = selectedAddOns.find(i => (i.Id == addOn.Id));
                if (activeAddOn) {
                    activeAddOn.Modelset = addOn.Modelset;
                    activeAddOn.PrimaryProductImage = addOn.PrimaryProductImage;
                }
                if (activeAddOn && !openGroups.includes(group.Id)) {
                    setOpenGroups([...openGroups, group.Id]);
                }
                groupData.addOns.push({
                    data: (activeAddOn || addOn), active: (activeAddOn != undefined),
                    allowMultiple: (group.AttachmentList.length == 1 || group.CanSelectMultiple) && group.GroupName != "Tires"
                });
            });
            groups.push(groupData);
        });
    }
    else {
        addOnGroups.forEach((group) => {
            const groupData = { id: group.Id, name: group.GroupName, addOns: [], open: openGroups.includes(group.Id) };
            group.OptionGroupList.forEach((optionGroup) => {
                optionGroup.AttachmentList.forEach((addOn) => {
                    const activeAddOn = selectedAddOns.find(i => (i.Id == addOn.Id));
                    if (activeAddOn) {
                        activeAddOn.Modelset = addOn.Modelset;
                        activeAddOn.PrimaryProductImage = addOn.PrimaryProductImage;
                    }
                    if (activeAddOn && !openGroups.includes(group.Id)) {
                        setOpenGroups([...openGroups, group.Id]);
                    }
                    groupData.addOns.push({
                        data: (activeAddOn || addOn), active: (activeAddOn != undefined),
                        allowMultiple: (optionGroup.AttachmentList.length == 1 || optionGroup.CanSelectMultiple) && group.GroupName != "Tires"
                    });
                });
            });
            groups.push(groupData);
        });
    }

    function toggleOpenGroup(id) {
        if (openGroups.includes(id)) {
            setOpenGroups(openGroups.filter(i => i != id));
        }
        else {
            setOpenGroups([...openGroups, id]);
        }
    }

    return (
        <div id="build_details_container_wholegoods" className="detailsContainerContent wholegoodsPanel right-list bottomBorder active">
            {packages?.length > 0 && (
                <div className="groupList bottomBorder">
                    <div className={openGroups.includes('packages') ? "groupListHeader active" : "groupListHeader"} onClick={() => toggleOpenGroup('packages')}><span>Packages</span><em className={openGroups.includes('packages') ? "bi bi-chevron-up" : "bi bi-chevron-down"}></em></div>
                    {openGroups.includes('packages') &&
                        <>
                            <Package active={!selectedPackage} toggle={() => tryAddOn()} baseUrl={baseUrl} allowMultiple={false} />

                            {packages.map((pack) => (
                                <Package key={(pack.package.id)} pack={pack.package} active={pack.package.id == selectedPackage} toggle={() => tryAddOn(pack)} baseUrl={baseUrl} allowMultiple={false} />
                            ))}
                        </>
                    }

                </div> 
            )}

            {groups.map((group) => (
                <div key={group.id} className="groupList bottomBorder">
                    <div className={group.open ? "groupListHeader active" : "groupListHeader"} onClick={() => toggleOpenGroup(group.id)}><span>{group.name}</span><em className={group.open ? "bi bi-chevron-up" : "bi bi-chevron-down"}></em></div>

                    {group.open && group.addOns.map((addOn) => (
                        <AddOn key={(addOn.data.Id)} addOnItem={addOn.data} active={addOn.active} toggle={() => tryAddOn(addOn.data)} baseUrl={baseUrl} allowMultiple={addOn.allowMultiple} />
                    ))}

                </div>
            ))}
        </div>
        
    );
}

export function BaseSummary({ selectedAddOns, header, material, linkToPage, baseUrl, baseModelPrice }) {

    if (!selectedAddOns) {
        return null;
    }

    return (
        <div id="build_details_container_selections_wholegoods" className="detailsContainerContent summaryPanel bottomBorder active">
            <div className="groupListHeader"><span>{header}</span><Link href="#" className="summarySelectionLink showWholegoods" to={linkToPage + window.location.search}>Edit &gt;</Link></div>
            <div className="materialDescription">{material}</div>
            {selectedAddOns.map((item) => (
                <AddOn key={item.Id} addOnItem={item} active={selectedAddOns.find(i => (i.Id == item.Id))} baseUrl={baseUrl} />
            ))}
            <div className="baseModelPriceContainer">
                <span className="label">                Base Model Price</span>
                <span className="value">{baseModelPrice}</span>
            </div>
        </div>

    );
}

export function AddOnSummary({ selectedAddOns, defaultAddOns, header, linkToPage, baseUrl }) {
    //const [searchParams, setSearchParams] = useSearchParams();
    //const addOns = searchParams.get('addons') ? searchParams.get('addons').split('|') : [];
    selectedAddOns = selectedAddOns.filter(addOn => !defaultAddOns.find(def => def.Material == addOn.Material));

    if (selectedAddOns.length == 0) {
        return null;
    }

    return (
        <div id="build_details_container_selections_wholegoods" className="detailsContainerContent summaryPanel bottomBorder active">
            <div className="groupListHeader"><span>{header}</span><Link href="#" className="summarySelectionLink showWholegoods" to={linkToPage + window.location.search}>Edit &gt;</Link></div>
            {selectedAddOns.map((item) => (
                <AddOn key={item.Id} addOnItem={item} active={selectedAddOns.find(i => (i.Id == item.Id))} baseUrl={baseUrl} />
            ))}
        </div>

    );
}

export function AddOn({ addOnItem, active, toggle = null, setQuantity = null, baseUrl, allowMultiple }) {
    let price = active ? 'See Dealer for Pricing' : 'Configure for Pricing';
    if (addOnItem.MsrpPrice) {
        price = '$' + addOnItem.MsrpPrice.toLocaleString();
    }

    const [quantity, setInternalQuantity] = useState(addOnItem.Quantity || 0);
    useEffect(() => {
        if (setQuantity) {
            setQuantity(quantity);
        }
    }, [quantity]);

    const updateQuantity = (e) => {
        let qty = parseInt(e.target.value.replace(/[^0-9]+/g, ''));

        if (!Number.isInteger(qty) || qty < 0) {
            qty = 0;
        }

        e.target.value = qty;
        setInternalQuantity(qty);
    }

    var thumbnail = false;
    if (addOnItem.ImagePath != undefined && addOnItem.ImagePath != null && addOnItem.ImagePath.length) {
        thumbnail = addOnItem.ImagePath;
    }
    else if (addOnItem.PrimaryProductImage != undefined && addOnItem.PrimaryProductImage.images['300px_uri'] != null) {
        thumbnail = addOnItem.PrimaryProductImage.images['300px_uri'];
    }
    // todo replace later for modelset thumbnails 
/*    else if (addOnItem.Modelset != undefined && addOnItem.Modelset != null) {
        window.model.products.forEach((product) => {
            product.productoptiongroups.forEach((group) => {
                group.productoptions.forEach((option) => {
                    if (option.modelset === addOnItem.Modelset) {
                        thumbnail = baseUrl + 'icons/' + encodeURIComponent(option.icon)
                    }
                });  
            });  
        });  
    }*/

    if (!active && !toggle && !setQuantity) {
        return null;
    }

    return (
        <div className="groupListOptionGroupContainer">
            <div className={"attachItemContainer" + ((!thumbnail) ? " nothumbnail" : "") + ((active) ? " active" : "")} onClick={toggle}>

                {thumbnail &&
                    <>
                        <em className="bi bi-check-circle-fill orange active-item"></em>
                        <div className="wholegoodsItemImage">
                            <img src={thumbnail} alt={addOnItem.AttachId} />
                        </div>
                    </>
                }
                <div className="wholegoodsItemDetailsContainer">
                    {!thumbnail && toggle &&
                        <em className={allowMultiple ? (active ? "bi bi-check-square-fill orange" : "bi bi-square")
                            : (active ? "bi bi-check-circle-fill orange" : "bi bi-circle")}></em>
                    }
                    <div className="wholegoodsItemDetailsNameContainer">
                        {addOnItem.Description.split(/<br\s*\/?>/i).map(desc => (
                            <div key={'wholegoodsItemDetailsName_' + desc.replace('\'', '')} className="wholegoodsItemDetailsName">{desc}</div>
                        ))}
                    
                        <div className="wholegoodsItemPrice ">
                            {price}
                            {active && !setQuantity && price && addOnItem.Quantity > 1 && (' x ' + addOnItem.Quantity)}

                            {(addOnItem.MsrpPrice || active) && (
                                <div className="partNum">{addOnItem.DisplayMaterial}</div>
                            )}
                        </div>
                        
                    </div>
                <div className="wholegoodsItemAction">
                    {toggle && (active ? (
                        <button className="action-button remove">Remove</button>
                    ) : (
                        <button className="action-button add">Choose</button>
                    ))}

                    {setQuantity && (
                        <>
                                <button className="action-button deacrease" onClick={() => setInternalQuantity(Math.max(quantity - 1, 0))}>-</button>
                                <input value={quantity} onChange={updateQuantity} />
                                <button className="action-button increase" onClick={() => setInternalQuantity(quantity + 1)}>+</button>
                        </>
                    )}
                </div>
                </div>
            </div>
        </div>
    );
}

export function AddOnImage({ addOnItem }) {
    var thumbnail = false;
    if (addOnItem.ImagePath != undefined && addOnItem.ImagePath != null && addOnItem.ImagePath.length) {
        thumbnail = addOnItem.ImagePath;
    }
    else if (addOnItem.PrimaryProductImage != undefined && addOnItem.PrimaryProductImage.images['300px_uri'] != null) {
        thumbnail = addOnItem.PrimaryProductImage.images['300px_uri'];
    }

    return (
        (thumbnail) ? (
            <img src={thumbnail} alt={addOnItem.AttachId} />
        ) : ('')
    );
}

export function Package({ pack, active, toggle = null, baseUrl, allowMultiple }) {
    let price = 0;
    if (pack) {
        pack.attachments.forEach(attachment => {
            price += attachment.MsrpPrice;
        });
        pack.landPrideAttachments.forEach(attachment => {
            price += attachment.MsrpPrice;
        });
        price = price ? '$' + price.toLocaleString() : 'See Dealer for Pricing';

        var thumbnail = false;
        if (pack.imageUrl != undefined && pack.imageUrl != null && pack.imageUrl.length) {
            thumbnail = pack.imageUrl;
        }
    }
    else {
        pack = {id: 0, name: "No Package"};
    }

    if (!active && !toggle) {
        return null;
    }

    return (
        <div className="groupListOptionGroupContainer">
            <div className={"attachItemContainer" + ((!thumbnail) ? " nothumbnail" : "") + ((active) ? " active" : "")}
                onClick={(pack.id != 0 || !active) ? toggle : null}>

                {thumbnail &&
                    <>
                        <em className="bi bi-check-circle-fill orange active-item"></em>
                        <div className="wholegoodsItemImage">
                            <img src={thumbnail} alt={pack.id} />
                        </div>
                    </>
                }
                <div className="wholegoodsItemDetailsContainer">
                    {!thumbnail && toggle &&
                        <em className={allowMultiple ? (active ? "bi bi-check-square-fill orange" : "bi bi-square")
                            : (active ? "bi bi-check-circle-fill orange" : "bi bi-circle")}></em>
                    }
                    <div className="wholegoodsItemDetailsNameContainer">
                        <div className="wholegoodsItemDetailsName">{pack.name}</div>

                        {price != 0 &&
                            <div className="wholegoodsItemPrice ">{price}</div>
                        }

                    </div>
                    <div className="wholegoodsItemAction">
                        {toggle && (active ? (
                            pack.id != 0 && (
                                <button className="action-button remove">Remove</button>
                            )
                        ) : (
                            <button className="action-button add">Choose</button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export function BaseItem({ addOnItem, summaryView = false, active, toggle, baseUrl, allowMultiple }) {
    const price = 'Included In Price';

    if (summaryView && !active) {
        return null;
    }

    var thumbnail = false;
    if (addOnItem.ImagePath != undefined && addOnItem.ImagePath != null && addOnItem.ImagePath.length) {
        thumbnail = addOnItem.ImagePath;
    }

    return (
        <div className="groupListOptionGroupContainer">
            <div className={"attachItemContainer" + ((!thumbnail) ? " nothumbnail" : "") + ((active && !summaryView) ? " active" : "")} onClick={toggle}>

                {thumbnail &&
                    <>
                        <em className="bi bi-check-circle-fill orange active-item"></em>
                        <div className="wholegoodsItemImage">
                            <img src={thumbnail} alt={addOnItem.AttachId} />
                        </div>
                    </>
                }
                <div className="wholegoodsItemDetailsContainer">
                    {!thumbnail && !summaryView &&
                        <em className={allowMultiple ? (active ? "bi bi-check-square-fill orange" : "bi bi-square")
                            : (active ? "bi bi-check-circle-fill orange" : "bi bi-circle")}></em>
                    }
                    <div className="wholegoodsItemDetailsNameContainer">
                        <div className="wholegoodsItemDetailsName">{addOnItem.Description.replace('<br />',)}</div>
                        <div className="wholegoodsItemPrice ">{price}</div>
                    </div>
                    {!summaryView &&
                        <div className="wholegoodsItemAction">
                            {active ? (
                                <button className="action-button remove">Remove</button>) : (<button className="action-button add">Choose</button>)}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}