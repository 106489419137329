import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ModalContext } from '../context/ModalProvider';
import Product from './Product';

export default function ProductList({ products }) {
    if (products.length === 0) {
        return (
            < div className="loader" >Loading...</div > // temp
        );
    }

    return (
        <div id="product_groups_container_content" className={products && products.length > 0 && products[0].ProductList && products[0].ProductList.length > 0 ? 'groupContainerContent modelList' : 'groupContainerContent'}>
            {products.map((product) => {
                if (product.ProductList?.length > 0 || product.BMKGroups?.length > 0) {
                    return <Product key={product.Id} product={product} />
                } else {
                    return <ProductGroup key={product.Id} productGroup={product} />
                }
            })}
        </div>
    );
}

function ProductGroup({ productGroup }) {
    const { productSlug } = useParams();
    let url = productGroup.Id + '-' + encodeURIComponent(productGroup.Name);
    if (productSlug) {
        url = '/' + encodeURIComponent(productSlug.trim()) + '/' + url;
    }

    if (productGroup.IsProductSeries && !productGroup.Name.includes('Series')) {
        productGroup.Name += ' Series';
    }

    const sendEvent = () => {
        window.gtag('event', 'Product Line Selected', {
            'Product Line': productGroup.Name
        });
    }

    let highlightedUses = [];
    if (productGroup.HighlightedUses != null) {
        highlightedUses = Object.values(productGroup.HighlightedUses).filter(use => use.Key);
    }

    const modal = useContext(ModalContext);
    const showDetails = (e) => {
        e.stopPropagation();
        e.preventDefault();

        modal.show(
            <div className="highlights-container">
                <h2 className="modal-title">{productGroup.Name} Details</h2>
                <div className="modal-image">
                    <img src={productGroup.ImageUrl} />
                </div>
                <div className="modal-highlights">
                    <h2>Highlighted Uses</h2>
                    <ul>
                        {highlightedUses.map(use => (
                            <li key={use.Key}>
                                <h3>{use.KeyAlias}</h3>
                                <p>{use.Value}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                <Link to={url} onClick={() => modal.show()}><button className="action-button">Choose {productGroup.Name}</button></Link>
                <button onClick={() => modal.show()} className="action-button cancel">Back</button>
            </div>
        );
    }

    const searchRegExp = /[\/]/g;
    const replaceWith = ' / ';

    return (
        <Link to={url} onClick={sendEvent} className="productGroupLink groupLink eligible">
            <span className="groupThumbContainer">
                <img src={productGroup.ImageUrl} className="groupThumb thumb" alt={productGroup.Name} />
            </span>
            <span className="headline">{productGroup.Name.replace(searchRegExp, replaceWith)}</span>
            {highlightedUses.length > 0 && (
                <span className="detailsLink" onClick={showDetails}><em className="bi bi-info-circle orange"></em> Details</span>
            )}
        </Link>
    );
}
