export default function ProductSlider({ slider }) {
    slider.banners.sort((a, b) => a.sort - b.sort);

    return (
        <div id="product_groups_container_slider" className="sliderContainerContent">
            <div id="carouselModels" className="carousel slide modelSlider" data-bs-ride="carousel">
                <div className="carousel-indicators modelSliderBullets">
                    {slider.banners.map((banner, index) => (
                        <button key={index} type="button" data-bs-target="#carouselModels" data-bs-slide-to={index} className={index == 0 ? " active" : ""} aria-label={'Go to ' + banner.headline} ></button>

                    ))}
                </div>
                <div className="carousel-inner">
                    {slider.banners.map((banner, index) => (
                        <div key={index} className={"carousel-item modelCarouselContainer" + (index == 0 ? " active" : "")} style={{ backgroundImage: 'url(' + banner.url + ')' }}>
                            <img src={banner.url} className="d-block w-100" alt={slider.keyValue + ' (' + banner.headline + ')'} />
                            <div className="carousel-caption d-none d-md-block modelCarouselCaptionContainer">
                                <span className="sliderModel">{slider.keyName}</span>
                                <span className="positionHeadline">{banner.headline}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
