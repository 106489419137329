import { createContext, useState, useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useParams, Link, useRouteLoaderData } from 'react-router-dom';
import { useGlobalState, setSavePopup, getSavePopup } from "../configurator/State";
import FullPrintSummary from '../configurator/FullPrintSummary';

export const ModalContext = createContext();

export default function ModalProvider({ children }) {
    const [modalContent, setModalContent] = useState();
    const productDetails = useRouteLoaderData("productDetails");
    const [user] = useGlobalState('User');
    const { productSlug, seriesSlug, modelName } = useParams();

    const [addOns] = useGlobalState("AddOns");
    const [prices] = useGlobalState("Prices");
    const [kmaintenance] = useGlobalState("KMaintenance");
    const [warranty] = useGlobalState("Warranty");
    const [insurance] = useGlobalState("Insurance");
    const [ZipCode] = useGlobalState("ZipCode");

    let saveBuildName = "";

    const fullProductURL = window.location.protocol + "//" + window.location.host + process.env.PUBLIC_URL + '/' +
        encodeURIComponent(productSlug) + '/' + encodeURIComponent(seriesSlug) + '/' + encodeURIComponent(modelName);

    const showDisclaimer = (index) => {
        const disclaimers = {
            1: `This List Price configuration program is for informational purposes only. Price on this sales
                quote is an estimate and is subject to being increased. Payment assumes the MSRP of your selected
                equipment and accessories, the length of term you have selected and any applicable special A.P.R.
                program. Not Included: *Taxes, shipping; handling, surcharges, assembly charges, destination,
                freight and/or delivery charges.  Not all models are available to order. Contact your dealer for available inventory stock or future availability.`,

            2: `This List Price configuration program is for informational purposes only. Price on this sales
                quote is an estimate and is subject to being increased. Payment assumes the MSRP of your selected
                equipment and accessories, the length of term you have selected and any applicable special A.P.R.
                program. Not Included: *Taxes, shipping; handling, surcharges, assembly charges, destination,
                freight and/or delivery charges.  Not all models are available to order. Contact your dealer for available inventory stock or future availability.`,

            3: `This List Price configuration program is for informational purposes only. Price on this sales
                quote is an estimate and is subject to being increased. Payment assumes the MSRP of your selected
                equipment and accessories, the length of term you have selected and any applicable special A.P.R.
                program. Not Included: *Taxes, shipping; handling, surcharges, assembly charges, destination,
                freight and/or delivery charges.  Not all models are available to order. Contact your dealer for available inventory stock or future availability.`,

            4: `This List Price configuration program is for informational purposes only. Price on this sales
                quote is an estimate and is subject to being increased. Payment assumes the MSRP of your selected
                equipment and accessories, the length of term you have selected and any applicable special A.P.R.
                program. Not Included: *Taxes, shipping; handling, surcharges, assembly charges, destination,
                freight and/or delivery charges.  Not all models are available to order. Contact your dealer for available inventory stock or future availability.`,

            5: `This List Price configuration program is for informational purposes only. Price on this sales
                quote is an estimate and is subject to being increased. Payment assumes the MSRP of your selected
                equipment and accessories, the length of term you have selected and any applicable special A.P.R.
                program. Not Included: *Taxes, shipping; handling, surcharges, assembly charges, destination,
                freight and/or delivery charges.  Not all models are available to order. Contact your dealer for available inventory stock or future availability.`,

            6: `Kubota's reputation for quality likely made your equipment purchase decision an easy one. Now let
                us take your confidence in Kubota to the next level with Kubota-Endorsed Property Damage Insurance.
                After all, who knows the value of your Kubota better than the company that engineered it with the
                quality you've come to trust? Protect your Kubota through Kubota Tractor Acceptance Corporation
                (KTAC) and ensure your peace of mind.`,

            7: `The Orange Protection Program is a Kubota-backed, no deductible extended warranty solution.
                The program is designed to keep you productive in the unfortunate event of a failure in material
                or workmanship after the expiration of the Kubota Limited Warranty by one or two years.`,
        }
        setModalContent(disclaimers[index]);
    }

    async function trySave() {
        if (!productDetails || !productDetails.Tractor || !productDetails.Tractor.Material || productDetails.Tractor.Material.length <= 0) {
            setModalContent(
                <>
                    <div className="modal-header">
                        <h2 className="modal-title">Finish Building</h2>
                    </div>
                    <div className="modal-body saveBuildModalBody">
                        <p>Please select and get started on your custom build out your Dream Vehicle before trying to Save.</p>
                    </div>
                </>
            );
        }
        else {
            const loginResponse = await fetch("/account/bmkchecklogin/");
            const loginJson = await loginResponse.json();

            if (loginJson.loggedIn) {
                showSave();
            }
            else {
                showLogin("Log In to Save Your Build", showSave);
            }
        }
    }
    async function showLogin(promptMessage, successHandler, errorMessage) {
        setModalContent(
            <>
                <div className="modal-header">
                    <h2 className="modal-title">{promptMessage}</h2>
                </div>
                <div className="modal-body saveBuildModalBody">
                    <form className="loginForm" onSubmit={(event) => handleLogin(promptMessage, successHandler, event)}>

                        <div>
                            <label for="UserName">Email Address:</label> <br />
                            <input class="input_100" name="UserName" type="text" />
                        </div>
                        <div>
                            <label for="Password">Password:</label><br />
                            <input autocomplete="off" class="input_100" name="Password" type="password" />
                        </div>
                        <div>
                            <label><input data-val="true" name="RememberMe" type="checkbox" value="true" /> Remember me?</label>
                        </div>
                        <div>
                            <p><button className="orange-button button saveBuildButton">Sign In</button></p>
                        </div>
                        <div className="error-message">{errorMessage}</div>
                        <div style={{ "text-align": "right" }}>
                            <a href="/account/recoverpassword" id="passRetrieve">Forgot your password?</a>
                        </div>
                    </form>
                </div>
            </>
        );
    }

    async function showSave() {
        setModalContent(
            <>
                <div className="modal-header">
                    <h2 className="modal-title">Save This Build?</h2>
                </div>
                <div className="modal-body saveBuildModalBody">
                    <p>If you leave you will lose your current configuration.  Name and save this build to add it to your account.</p>
                    <input type="text" id="txtSaveBuildName" name="txtSaveBuildName" onBlur={updateSaveBuildName} onChange={updateSaveBuildName} maxLength="50" required={true} />
                    <button id="save-this-build-button" className="orange-button button saveBuildButton" onClick={saveAndConfirmBuild}>
                        <span>Save</span>
                    </button>
                </div>
            </>
        );
    }

    async function tryLoad() {
        const loginResponse = await fetch("/account/bmkchecklogin/");
        const loginJson = await loginResponse.json();

        if (loginJson.loggedIn) {
            showLoad();
        }
        else {
            showLogin("Log In to View Your Builds", showLoad);
        }
    }

    async function showLoad() {
        const loadResponse = await fetch("/inventory/bmkloadbuilds/");
        const loadJson = await loadResponse.json();
        console.log(loadJson);
        setModalContent(
            <>
                <div className="modal-header">
                    <h2 className="modal-title">Welcome Back</h2>
                </div>
                <div className="modal-body saveBuildModalBody">
                    {loadJson.length > 0 &&
                        <div className="savedBuilds">
                            <h3>Would you like to continue one of your saved builds?</h3>
                            {loadJson.map(build => (
                                <a className="savedBuild" href={build.ProductURL + "?build-id=" + build._id}>
                                    <img src={build.Image} />
                                    <div className="buildName">{build.BuildName}</div>
                                    <button className="button orange-button">Choose</button>
                                </a>
                            ))}
                            <div className="savedBuildDivider">or</div>
                        </div>
                    }
                    <button id="save-this-build-button" className="button saveBuildButton" onClick={saveAndConfirmBuild}>
                        <span>Start New Build</span>
                    </button>
                </div>
            </>
        );
    }

    useEffect(() => {
        document.addEventListener('mouseout', e => {
            if (!e.toElement && !e.relatedTarget && getSavePopup()) {
                // trySave();
                setSavePopup(false);
            }
        });
    }, []);

    async function handleLogin(promptMessage, successHandler, event) {
        event.preventDefault();

        const loginResponse = await fetch("/account/logonpopupform", {
            method: "POST",
            body: new FormData(event.target)
        });
        const loginMessage = await loginResponse.text();

        if (loginMessage === "Login Successfull") {
            successHandler();
        }
        else {
            showLogin(promptMessage, successHandler, loginMessage);
        }
    }

    async function saveAndConfirmBuild() {
        setModalContent(
            <>
                <div className="modal-header">
                    <h2 className="modal-title">Saving...</h2>
                </div>
            </>
        );

        await saveBuild();

        window.gtag('event', 'Save', {
            'Model': productDetails.Tractor.Name
        });

        setModalContent(
            <>
                <div className="modal-header">
                    <h2 className="modal-title">Build Saved Successfully</h2>
                </div>
                <div className="modal-body saveBuildModalBody">
                    <p>Your Build Has Been Successfully Saved!</p>
                </div>
            </>
        );
    }

    const updateSaveBuildName = (e) => {
        saveBuildName = e.target.value;
    }

    async function showShare() {
        setModalContent(
            <>
                <div className="modal-header">
                    <h2 className="modal-title">Loading...</h2>
                </div>
            </>
        );

        const savedBuild = await saveBuild();

        const shareURL = savedBuild.ProductURL + '?build-id=' + savedBuild._id;

        window.gtag('event', 'Share', {
            'Model': productDetails.Tractor.Name
        });

        setModalContent(
            <>
                <div className="modal-header">
                    <h2 className="modal-title">Share This Build?</h2>
                </div>
                <div className="modal-body saveBuildModalBody">
                    <a target="_blank" className="share-button" onClick={() => sendToIKQ(3)} href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(shareURL) + "&t=" + encodeURIComponent(savedBuild.BuildName)}>
                        <em className="bi bi-facebook"></em>
                        <span>Facebook</span>
                    </a>

                    <a target="_blank" className="share-button" onClick={() => sendToIKQ(3)} href={"http://www.linkedin.com/shareArticle?url=" + encodeURIComponent(shareURL) + "&title=" + encodeURIComponent(savedBuild.BuildName)}>
                        <em className="bi bi-linkedin"></em>
                        <span>LinkedIn</span>
                    </a>
                    <button className="share-button" onClick={shareByEmail}>
                        <em className="bi bi-envelope-fill"></em>
                        <span>Email</span>
                    </button>
                    <button className="share-button" onClick={() => navigator.clipboard.writeText(shareURL)}>
                        <em className="bi bi-files"></em>
                        <span>Copy Link</span>
                    </button>
                </div>
            </>
        );
    }

    function shareByEmail() {
        setModalContent(
            <>
                <div className="modal-header">
                    <h2 className="modal-title">Share By Email</h2>
                </div>
                <div className="modal-body saveBuildModalBody">
                    <form onSubmit={submitShareByEmail}>
                        <input type="text" name="email" placeholder="Email" />
                        <button type="submit" className="send-quote-button orange-button">Share</button>
                    </form>
                </div>
            </>
        );
    }

    function submitShareByEmail(e) {
        e.preventDefault();
        e.stopPropagation();

        let email = (new FormData(e.currentTarget)).get('email');
        sendToIKQ(2, { email: email });

        setModalContent(
            <>
                <div className="modal-header">
                    <h2 className="modal-title">Share By Email</h2>
                </div>
                <div className="modal-body saveBuildModalBody">
                    <p>Thank You! Your build has been sent to {email}</p>
                </div>
            </>
        );
    }

    async function saveBuild() {
        setSavePopup(false);

        if (saveBuildName.length <= 0)
            saveBuildName = 'My Dream ' + productDetails.Tractor.Material;

        const savedBuild = {
            ProductURL: fullProductURL,
            SavedURL: window.location.href,
            ConfiguredItems: JSON.stringify(productDetails.configuration.configuredItems),
            BuildName: saveBuildName,
            UC: user
        };

        const modelSets = [productDetails.Tractor.Modelset];
        const defaultView = Object.keys(productDetails.studios)[0];
        Object.values(productDetails.configuration.configuredItems).forEach((item) => {
            if (item.Modelset) {
                modelSets.push(item.Modelset);
            }
        });
        const matchingImage = productDetails.imageMappingData.find((imd) => {
            if (!productDetails.studios[defaultView] || imd.studio !== productDetails.studios[defaultView].id) {
                return false;
            }
            if (imd.modelsets.length !== modelSets.length) {
                return false;
            }
            if (!imd.modelsets.every((ms) => modelSets.includes(ms))) {
                return false;
            }
            return true;
        });
        if (matchingImage) {
            savedBuild.Image = productDetails.baseUrl + 'images/' + encodeURIComponent(matchingImage.image);
        }
        else {
            savedBuild.Image = productDetails.Tractor.ImagePath;
        }

        console.log('posting...');
        const productResponse = await fetch('/inventory/bmksavebuild',
            { method: "POST", body: JSON.stringify(savedBuild), headers: { 'content-type': 'application/json' } }
        );
        const productJson = await productResponse.json();
        console.log(productJson);

        return productJson;
    }

    async function sendToIKQ(actionType = 1, customerData = {}) {
        let quote = productDetails.configuration.createQuoteRequest();
        quote.customer = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            comments: '',
            zip: ZipCode,
            selectedDealer: '',
            overSixteen: 'True',
            optInMarketing: 'False',

            ...customerData
        };
        quote.userAction = actionType;
        quote.ipAddress = '127.0.0.1';

        quote.cashCIRAmount = prices.totalPrice;
        quote.financeCIRAmount = prices.monthlyPayment;
        quote.orangePlusAmount = 0;
        quote.interestInKtac = insurance || false;
        quote.interestInKMaintenance = kmaintenance || false;
        quote.interestInWarranty = warranty || false;
        quote.selectedPackageId = productDetails.configuration.package?.id;

        let response = await fetch('/inventory/ikqsavedata',
            { method: "POST", body: JSON.stringify(quote) }
        );
        let json = await response.json();

        console.log('just posted quote');
        console.log(json);
        console.log('quote finished');

        if (!json || !json.BMKQuoteId) {
            return false;
        }

        let htmlContents = renderToStaticMarkup(
            <FullPrintSummary product={productDetails} prices={prices} selectedAddOns={addOns} quote={quote} quoteid={json.BMKQuoteId} printContents="detailed" />
        );

        const data = new FormData();
        data.type = "multipart/form-data";
        data.append("printContents", htmlContents);
        data.append("bmkQuoteId", json.BMKQuoteId);

        let fileResponse = await fetch('/inventory/ikqsavepdf',
            {
                method: "POST", body: data
            }
        );
        let fileJson = await fileResponse.json();

        console.log('starting to send file');
        console.log(fileJson);
        console.log('ending to send file');

        return json.BMKQuoteId;
    }

    return (
        <ModalContext.Provider value={{
            show: setModalContent,
            showDisclaimer, trySave, tryLoad, showShare, sendToIKQ
        }}>
            {modalContent ? <Modal closeHandler={() => setModalContent()}>{modalContent}</Modal> : null}
            {children}
        </ModalContext.Provider>
    )
}

function Modal({ children, closeHandler }) {
    return (
        <div id="modalSeriesDetails" className="modal modalSeriesDetails show" tabIndex="-1" role="dialog" aria-modal="true">
            <div className="modal-backdrop" onClick={closeHandler}></div>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button type="button" className="close modal-close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" onClick={closeHandler}>x</span>
                    </button>
                    <div className="modal-body config-validation">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}