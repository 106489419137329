import { useState, useContext, useRef, useEffect } from 'react';
import { useGlobalState, setInsurance, setWarranty, setKMaintenance, setZipCode, setDealerNumber, setDealerGroup } from "./State";
import { AddOnSummary } from './AddOnList';
import { BaseSummary } from './AddOnList';
import FullPrintSummary from './FullPrintSummary';
import { ModalContext } from '../context/ModalProvider';
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

export default function ProductSummary({ product, prices, selectedAddOns, offers, setKSalesProPrice }) {
    //todo delete - const [searchParams, setSearchParams] = useSearchParams();

    let summaryImage = product.Tractor.ImagePath;

    const modelSets = [product.Tractor.Modelset];
    const defaultView = Object.keys(product.studios)[0];
    Object.values(product.configuration.configuredItems).forEach((item) => {
        if (item.Modelset) {
            modelSets.push(item.Modelset);
        }
    });
    const matchingImage = product.imageMappingData.find((imd) => {
        if (!product.studios[defaultView] || imd.studio !== product.studios[defaultView].id) {
            return false;
        }
        if (imd.modelsets.length !== modelSets.length) {
            return false;
        }
        if (!imd.modelsets.every((ms) => modelSets.includes(ms))) {
            return false;
        }
        return true;
    });
    if (matchingImage) {
        summaryImage = product.baseUrl + 'images/' + encodeURIComponent(matchingImage.image);
    }

    return (
        <div className="right-list summary">
            <div id="build_details_container_summary" className="detailsContainerContent summaryPanel summaryTop bottomBorder">
                <div id="build_details_container_summary_image" className="summaryTopImage">
                    <span className="groupThumbContainer">
                        <img src={summaryImage} className="groupThumb thumb useActiveFrontImage" alt="Your Custom Build" />
                    </span>
                </div>
                <div id="build_details_container_summary_text">
                    <span className="modelLabel">Your Custom</span>
                    <span className="model" data-wire-up="Tractor.Name">{product.Tractor.BaseModel}</span>
                </div>
            </div>

            <BaseSummary header='Base Model' material={product.Tractor.Material + ' ' + product.Tractor.Description} selectedAddOns={Object.values(product.configuration.includedAttachments)} linkToPage='.' baseUrl={product.baseUrl} baseModelPrice={prices.formattedBasePrice} />

            <AddOnSummary header='Kubota Add-Ons' linkToPage='.' selectedAddOns={selectedAddOns.filter(addOn => addOn.Manufacturer.replace(/[^a-z0-9]/gi, '') != "LandPride")} defaultAddOns={product.configuration.includedAttachments} baseUrl={product.baseUrl} />
            <AddOnSummary header='Land Pride Add-Ons' linkToPage='land-pride' selectedAddOns={selectedAddOns.filter(addOn => addOn.Manufacturer.replace(/[^a-z0-9]/gi, '') == "LandPride")} defaultAddOns={product.configuration.includedAttachments} baseUrl={product.baseUrl} />
            <PhysicalDamageInsurancePanel />
            <WarrantyPanel />
            <OffersPanel offers={offers} />

            <PriceSummary prices={prices} setKSalesProPrice={setKSalesProPrice} />

            <Dealers product={product} prices={prices} addOns={selectedAddOns} offers={offers}  />
        </div>
    );
}

function PriceSummary({ prices, setKSalesProPrice }) {
    const [Insurance] = useGlobalState("Insurance");
    const [KMaintenance] = useGlobalState("KMaintenance");
    const [Warranty] = useGlobalState("Warranty");
    const [PurchaseType] = useGlobalState("PurchaseType");
    const [Term] = useGlobalState("Term");
    const [SalesPerson] = useGlobalState("SalesPerson");

    const [taxRate, setTaxRate] = useState('0%');

    //todo - const [searchParams, setSearchParams] = useSearchParams();
    const modal = useContext(ModalContext);

    const selectAll = (e) => {
        e.target.select();
    }

    const updateTaxRate = (e) => {
        setTaxRate(e.target.value.replace(/[^0-9.]+/g, ''));
    }

    const formatTaxRate = (e) => {
        let newRate = parseFloat(e.target.value);
        if (isNaN(newRate)) {
            newRate = 0;
        }
        setKSalesProPrice('Tax', (newRate / 100) * (prices.basePrice + prices.addOnPrice + prices.landPridePrice));
        setTaxRate(newRate + '%');
    }

    const updateKSalesProPrice = (e) => {
        let intVal = parseInt(e.target.value.replace(/[^0-9]+/g, ''));
        // Allow for negative Misc values
        if (e.target.name === 'Misc') {
            let negative = e.target.value.includes('-');
            if (negative) {
                intVal = !isNaN(intVal) ? -intVal : '-';
            }
        }
        setKSalesProPrice(e.target.name, intVal);
    }

    return (
        <div id="build_details_container_pricing_summary" className="detailsContainerContent summaryPanel bottomBorder">
            <div className="groupListHeader"><span>Pricing Summary</span></div>
            <div className="pricingDetailsContainer small">
                <div className="itemContainer">
                    <div className="key">Base Model</div><div className="value"><span>{prices.formattedBasePrice}</span></div>
                    <div className="key">Kubota Add-Ons</div><div className="value"><span>{prices.formattedAddOnPrice}</span></div>
                    <div className="key">Land Pride Add-Ons</div><div className="value"><span>{prices.formattedLandPridePrice}</span></div>

                    {(prices.formattedDownPayment != 'See Dealer for Pricing') ?
                    <>
                        <div className="key">Down Payment</div><div className="value">-&nbsp;<span>{prices.formattedDownPayment}</span></div>
                        </> :
                        ""
                    }
                    {SalesPerson && <>
                        <div className="key">Tax Rate</div>
                        <div className="value"><input name="TaxRate" onChange={updateTaxRate} onFocus={selectAll} onBlur={formatTaxRate} value={taxRate} /></div>
                    </>}
                    <div className="key">Tax</div><div className="value">{prices.formattedTax}</div>
                    
                    {Warranty &&
                        <><div className="key">Orange Protection</div><div className="value">{SalesPerson ? <input name="Warranty" onChange={updateKSalesProPrice} value={prices.formattedWarranty} /> : prices.formattedWarranty}</div></>
                    }
                    {KMaintenance &&
                        <><div className="key">K-Maintenance</div><div className="value">{SalesPerson ? <input name="KMaintenance" onChange={updateKSalesProPrice} value={prices.formattedKMaintenance} /> : prices.formattedKMaintenance}</div></>
                    }
                    <div className="key">Freight</div><div className="value">{SalesPerson ? <input name="Freight" onChange={updateKSalesProPrice} onFocus={selectAll} value={prices.formattedFreight} /> : prices.formattedFreight}</div>
                    {Insurance &&
                        <><div className="key">KTAC PDI</div><div className="value">{SalesPerson ? <input name="KTAC" onChange={updateKSalesProPrice} onFocus={selectAll} value={prices.formattedKTAC} /> : prices.formattedKTAC}</div></>
                    }
                    <div className="key">Labor/Assembly</div><div className="value">{SalesPerson ? <input name="Labor" onChange={updateKSalesProPrice} onFocus={selectAll} value={prices.formattedLabor} /> : prices.formattedLabor}</div>
                    {SalesPerson && <>
                        <div className="key">Misc. Adjustments</div>
                        <div className="value"><input name="Misc" onChange={updateKSalesProPrice} onFocus={selectAll} value={prices.formattedMisc} /></div>
                    </>}
                    <div className="key">Total MSRP</div><div className="value"><span>{prices.formattedTotalPrice}</span></div>
                </div>
            </div>
            <div className="pricingDetailsContainer estimatedNetPricingBox large">
                <div className="itemContainer">
                    <div className="key"><span>Estimated Net Price<sup onClick={() => modal.showDisclaimer(1)}>1</sup></span></div><div className="value"><span>{prices.formattedTotalPrice}</span></div>
                </div>
            </div>
            <div className="pricingDisclaimerBox">
                This List Price configuration program is for informational purposes only. Price on this sales quote is an estimate and is subject to being increased. Payment assumes the MSRP of your selected equipment and accessories, the length of term you have selected and any applicable special A.P.R. program. Not Included: *Taxes, shipping; handling, surcharges, assembly charges, destination, freight and/or delivery charges.<br /><br />
                <strong>Not all models are available to order. Contact your dealer for available inventory stock or future availability.</strong>
            </div>
            {(PurchaseType === 'finance' && Term) &&
                <div className="pricingDetailsContainer large">
                    <div className="itemContainer">
                        <div className="key"><span>Estimated Monthly Payment<sup onClick={() => modal.showDisclaimer(2)}>2</sup></span></div><div className="value"><span>{prices.formattedMonthlyPayment}</span></div>
                    </div>
                </div>
            }
        </div>
    );
}

export function PhysicalDamageInsurancePanel({ prices }) {
    const [Insurance] = useGlobalState("Insurance");
    const modal = useContext(ModalContext);

    return (
        <div id="build_details_container_physical_damage_insurance" className="detailsContainerContent summaryPanel right-list bottomBorder">
            <div className="groupListHeader"><span>Physical Damage Insurance</span></div>
            <div className="selectionContainer">
                <div className={"attachItemContainer ktacItem specialOffersItem" + (Insurance ? " active" : "")} onClick={() => setInsurance((Insurance) ? false : true)}>
                    <em className="bi bi-check-circle-fill orange active-item"></em>
                    <div className="wholegoodsItemImage centerSmall">
                        <img src="https://shop.kubotausa.com/assets/kubotatractor/images/cogwheel-icon.svg" alt="Physical Damage Insurance" />
                    </div>
                    <div className="wholegoodsItemDetailsContainer">
                        <div className="wholegoodsItemDetailsNameContainer">
                            <div className="wholegoodsItemDetailsName">
                                <span>Physical Damage Insurance through KTAC <a href="#" className="modelContentTooltip toolTipLink attachmentDetailsList" onClick={() => modal.showDisclaimer(6)}><em className="bi bi-info-circle orange"></em></a></span>
                            </div>
                            <div className="wholegoodsItemPrice ">
                                <span className="offerDetails">SEE DEALER FOR PRICING</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function KMaintenancePanel() {
    const [KMaintenance] = useGlobalState("KMaintenance");

    return (
        <>
            <div className="groupListHeader"><span>K-Maintenance Offers</span></div>
            <div className="selectionContainer">
                <div className={"attachItemContainer warrantyItem specialOffersItem" + (KMaintenance ? " active" : "")} onClick={() => setKMaintenance((KMaintenance) ? false : true)}>
                    <em className="bi bi-check-circle-fill orange active-item"></em>
                    <div className="wholegoodsItemImage centerSmall">
                        <img src="https://shop.kubotausa.com/assets/kubotatractor/images/shield-icon.svg" alt="Discuss K-Maintenance" />
                    </div>
                    <div className="wholegoodsItemDetailsContainer">
                        <div className="wholegoodsItemDetailsNameContainer">
                            <div className="wholegoodsItemDetailsName">
                                <span>Maintenance options available</span>
                            </div>
                            <div className="wholegoodsItemPrice ">
                                <span className="offerDetails">SEE DEALER FOR OPTIONS</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export function WarrantyPanel() {
    const [Warranty] = useGlobalState("Warranty");

    return (
        <div id="build_details_container_warranty" className="detailsContainerContent summaryPanel right-list bottomBorder">
            <div className="groupListHeader"><span>Orange Protection (Extended Warranty) Offers</span></div>
            <div className="selectionContainer">
                <div className={"attachItemContainer warrantyItem specialOffersItem" + (Warranty ? " active" : "")} onClick={() => setWarranty((Warranty) ? false : true)}>
                    <em className="bi bi-check-circle-fill orange active-item"></em>
                    <div className="wholegoodsItemImage centerSmall">
                        <img src="https://shop.kubotausa.com/assets/kubotatractor/images/shield-icon.svg" alt="Discuss Warranty" />
                    </div>
                    <div className="wholegoodsItemDetailsContainer">
                        <div className="wholegoodsItemDetailsNameContainer">
                            <div className="wholegoodsItemDetailsName">
                                <span>Kubota-backed extended protection solutions</span>
                            </div>
                            <div className="wholegoodsItemPrice ">
                                <span className="offerDetails">SEE DEALER FOR OPTIONS</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <KMaintenancePanel />
        </div>
    );
}

export function OffersPanel({ offers }) {
    return ('');
}

function Dealers({product, prices, addOns, offers }) {
    const [ZipCode] = useGlobalState("ZipCode");
    const [DealerNumber] = useGlobalState("DealerNumber");
    const [DealerGroup] = useGlobalState("DealerGroup");
    const [SalesPerson] = useGlobalState("SalesPerson");
    const printableSummaryComponentRef = useRef();

    useEffect(() => {
        if (DealerGroup != null) {
            fetch('/location/searchbydealergroupnumber?dn=' + DealerGroup)
                .then(response => response.json())
                .then(json => {
                    if (json.length > 0) {
                        setDealers(json);
                        console.log('dealers [' + dealers + ']');
                        return {};
                    }
                    else {
                        setDealerGroup(null);
                    }
                });
        }
    }, [DealerGroup]);

    const [quotePromise, setQuotePromise] = useState({ quoteId: 0, printContents: "overview", resolve: undefined });

    useEffect(() => {
        if (quotePromise.resolve) {
            quotePromise.resolve();
        }
    }, [quotePromise]);

    const printModal = () => {
        modal.show(
            <div className="modal-body print-modal">
            <div className="modal-header">
                <h2 className="modal-title">Print Options</h2>
                </div>
                <div className="print-options">
                    <ReactToPrint
                        trigger={() => (<button className="orange-button action-button">Overview</button>)}
                        content={() => (printableSummaryComponentRef.current)}
                        onBeforeGetContent={() => sendOnPrint("overview")}
                    />
            
                    <ReactToPrint
                        trigger={() => (<button className="orange-button action-button">Detailed</button>)}
                        content={() => (printableSummaryComponentRef.current)}
                        onBeforeGetContent={() => sendOnPrint("detailed")}
                    />
                </div>
            </div>
        );
    }

    async function sendOnPrint(printContents) {
        modal.show('Loading Printout...');
        let quoteId = await modal.sendToIKQ(4);
        modal.show();

        window.gtag('event', 'Print', {
            'Model': product.Tractor.Name
        });

        return new Promise((resolve) => {
            setQuotePromise({ quoteId, printContents, resolve });
        });
    }

    function updateSelectedDealerNumber(dealerNumber) {
        setDealerNumber(dealerNumber);
    }

    let KubotaLocatorRequest = {
        LocationQuery: "",
        Equipment: "all",
        IsRegional: false,
        State: ""
    };

    const [dealers, setDealers] = useState({});

    const updateSearchDealerZip = (e) => {
        setZipCode(e.target.value.replace(/[^0-9]+/g, ''));
    }

    const modal = useContext(ModalContext);

    const searchDealers = () => {
        if (ZipCode != null && ZipCode.length > 0) {
            console.log('here we go');

            let mappedSeries = product.Tractor.Series;
            console.log(mappedSeries);
            if (mappedSeries.startsWith('Z')) {
                mappedSeries = 'F';
            }

            KubotaLocatorRequest.LocationQuery = ZipCode;
            KubotaLocatorRequest.Equipment = mappedSeries;

            fetch('/location/searchbyaddress',
                {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(KubotaLocatorRequest),
                }
            )
                .then(response => response.json())
                .then(json => {
                    console.log(json);
                    console.log('product.Tractor.Series [' + product.Tractor.Series + ']');

                    const validDealers = json.filter(dealer => dealer.ProductCodes.split(",").includes(mappedSeries));
                    if (validDealers.length > 0) {
                        setDealers(validDealers);
                        return {};
                    }
                    else {
                        console.log('no dealers');
                        modal.show(
                            <>
                                <div className="modal-header">
                                    <h2 className="modal-title">No Dealers Found</h2>
                                </div>
                                <div className="modal-body config-validation">
                                    <span className="validation-headline center">We could not find any dealers matching {ZipCode}</span>
                                </div>
                            </>
                        );
                    }
                });
        }
        else {
            modal.show(
                <>
                    <div className="modal-header">
                        <h2 className="modal-title">Enter Your Zip Code</h2>
                    </div>
                    <div className="modal-body config-validation land-pride">
                        <span className="validation-headline center">Enter Your Zip Code to Find Dealers Near You</span>
                    </div>
                </>
            );
        }
    }

    return (
        <div id="build_details_container_dealers" className="detailsContainerContent summaryPanel right-list bottomBorder">
            {(SalesPerson && DealerNumber) ? (
                <>
                </>
            ): (
                <>
                    <div className="groupListHeader"><span>Send to Nearest Dealer</span></div>
                    {!DealerGroup &&
                        <div className="selectionContainer dealerSelectionContainer">
                            <input type="tel" id="dealersearchfield" placeholder="Zip Code" onChange={updateSearchDealerZip} onBlur={updateSearchDealerZip} />
                            <button id="searchDealersPayment" value={ZipCode} onClick={searchDealers} className="ui-button orange-button">
                                <span>Search</span>
                            </button>
                        </div>
                    }
                    {(dealers.length > 0) ? (
                        <>
                            <input type="hidden" name="dealerNumber" id="dealerNumber" value="11111" />
                            <div className="dealerResultsContainer">
                                {dealers.map((item) => (
                                    <a key={'dealerSelection-' + item.DealerNumber} className={(DealerNumber === item.DealerNumber) ? "dealerResult active" : "dealerResult"} onClick={() => updateSelectedDealerNumber(item.DealerNumber)}>
                                        <em className="bi bi-geo-alt-fill orange"></em>
                                        <span className="dealerDetailsContainer">
                                            <strong data-dealer-number={item.DealerNumber}>{item.DealerName}</strong>
                                            <span className="dealerDetails">{item.Address.City}, {item.Address.State} {item.Address.Zip}</span>
                                        </span>
                                    </a>
                                ))}
                            </div>
                        </>
                    ) :
                        <>
                            <span className="chooseDealerNote">Find a Dealer to Send Quote</span>
                        </>
                    }
                </>
            )}
            {SalesPerson ? (
                <>
                    {(DealerNumber) ? (
                        <Link id="button-quote" className="orange-button button showQuote nextButton firstRow" to={'quote' + window.location.search}>
                            <span>Send Quote</span>
                        </Link>
                    ) : (
                        <Link id="button-quote" className="orange-button button showQuote nextButton firstRow gray">
                            <span>Send Quote</span>
                        </Link>
                    )}
                    <Link id="button-quote" className="orange-button button showQuote nextButton withPrint" to={'email' + window.location.search}>
                        <span>Send Email</span>
                    </Link>
                </>
            ) : (
                <>
                    {(dealers.length > 0 && DealerNumber) ? (
                        <Link id="button-quote" className="orange-button button showQuote nextButton withPrint" to={'quote' + window.location.search}>
                            <span>Send Quote</span>
                        </Link>
                    ) : (
                        <Link id="button-quote" className="orange-button button showQuote nextButton withPrint gray">
                            <span>Send Quote</span>
                        </Link>
                    )}
                </>
            )}
            
            <button id="printButtonSummary" className="send-quote-button orange-button nextButton printButton" onClick={printModal}>Print</button>

            <div id="summaryFormHtmlContainer" className="printSummaryOutputHolder">
                <div ref={printableSummaryComponentRef}>
                    <FullPrintSummary product={product} prices={prices} selectedAddOns={addOns} offers={offers} quoteid={quotePromise.quoteId} printContents={quotePromise.printContents} />
                </div>
            </div>
        </div>
    );
}