import { useEffect } from 'react';
import NavBar from './NavBar';
import { Outlet, useLocation } from 'react-router-dom';
import ModalProvider from '../context/ModalProvider';
import Footer from './Footer';
import Cookies from 'universal-cookie';

export default function BuildMyKubota() {
    const cookies = new Cookies();

    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    console.log(uuidv4());

    let cookie = cookies.get('bmkUc');
    var date = new Date(); // Now
    date.setDate(date.getDate() + 30); // Set now + 30 days as the new date

    cookies.set('bmkUc', ((cookie && cookie.length > 0) ? cookie : uuidv4()), { path: '/', expires: date });

    // Track Eloqua pageview on location change
    const location = useLocation();
    useEffect(() => {
        window._elqQ.push(['elqTrackPageView', window.location.href]);
    }, [location]);

    return (
        <ModalProvider>
            <div className="BuildMyKubota">
                <NavBar/>

                <div className="container main-content">
                    <div className="text-center">
                        <div className="container_16">
                            <Outlet />
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </ModalProvider>
    );
}
