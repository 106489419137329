import { useContext } from 'react';
import { useParams, Link, useRouteLoaderData } from 'react-router-dom';
import { ModalContext } from '../context/ModalProvider';
import { useGlobalState } from "../configurator/State";

export default function NavBar() {
    const { productSlug, seriesSlug, modelName, pageName } = useParams();
    const productDetails = useRouteLoaderData("productDetails");
    const modal = useContext(ModalContext);
    const [draftData] = useGlobalState("DraftData");
    const [changeApi] = useGlobalState("ChangeAPI");
    const [navMode] = useGlobalState("NavigationMode");

    const urls = {};
    let step = 1;
    if (productSlug) {
        step = 2;
        urls['step2'] = '/' + encodeURIComponent(productSlug);

        if (seriesSlug) {
            urls['step3'] = urls['step2'] + '/' + encodeURIComponent(seriesSlug);
            step = 3;
        }
        if (modelName) {
            urls['step4'] = urls['step3'] + '/' + encodeURIComponent(modelName);
            step = 4;

            if (pageName === 'land-pride') {
                step = 5;
            }
            if (pageName === 'summary' || pageName === 'quote') {
                step = 6;
            }
        }
    }

    return (
        <>
            <nav className={(draftData || changeApi) ? "navbar navbar-expand-lg navbar-draft" : "navbar navbar-expand-lg"}>
                <div className="container">
                    <Link className="navbar-brand logoContainer" to="/">
                        <img className="style-logo" src="https://vnext.scdn4.secure.raxcdn.com/assets/kubotatractor/images/headerlogo-1000.png" alt="Kubota Logo" />
                        </Link>

                        <h2>
                            {draftData &&
                                <span>Draft Data Enabled</span>
                            }
                            {changeApi &&
                                <span>Change API Enabled</span>
                            }
                        </h2>

                    <ul className="navbar-nav">
                        <li className="nav-item findDealer">
                            <a className="nav-link" href="https://www.kubotausa.com/find-a-dealer" target="_blank"><em className="bi bi-geo-alt-fill"></em><span>Find a Dealer</span></a>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link saveBuild" onClick={() => modal.trySave()}><em className="bi bi-heart-fill"></em></button>
                        </li>
                        <li className="nav-item">
                            <button className="nav-link featureComing" onClick={() => modal.tryLoad()}><em className="bi bi-person-fill"></em></button>
                        </li>
                    </ul>
                </div>
            </nav>

            <div className="container progress-bar">
                <div className="linksSlider">
                    <div className="links">
                        <Link className={(step === 1) ? 'active' : ''} to='/'>Start</Link>
                        <Link className={(step === 2) ? 'active' : ''} to={urls['step2']}>Series</Link>
                        <Link className={(step === 3) ? 'active' : ''} to={urls['step3']}>Model</Link>
                        {navMode !== "landpride" &&
                            <Link className={(step === 4) ? 'active' : ''} to={urls['step4'] + window.location.search}>Kubota Add-Ons</Link>
                        }
                        {(navMode === "landpride" || (productDetails && productDetails.AttachHierarchy.LandPrideGroup)) &&
                            <Link className={(step === 5) ? 'active' : ''} to={urls['step4'] + '/land-pride' + window.location.search}>Land Pride Add-Ons</Link>
                        }
                        <Link className={(step === 6) ? 'active summary' : 'summary'} to={urls['step4'] + '/summary' + window.location.search}>Summary</Link>
                    </div>
                </div>
                <div className="bar">
                    <div className={'color active step' + step}>&nbsp;</div>
                </div>
            </div>
        </>
    );
}