import { useState, useEffect, useContext } from 'react';
import { useParams, Link, useSearchParams, useLoaderData } from 'react-router-dom';
import { useGlobalState, setSavePopup, setAddOns, setPrices } from "./State";
import { ModalContext } from '../context/ModalProvider';
import ProductImages from './ProductImages';
import ProductPricing from './ProductPricing';
import ProductSummary from './ProductSummary';
import FullPrintSummary from './FullPrintSummary';
import AddOnList, { AddOn } from './AddOnList';
import QuoteForm from './QuoteForm';

export default function ProductDetails() {
    const [Term] = useGlobalState("Term");
    const [PurchaseType] = useGlobalState("PurchaseType");
    const [DownPayment] = useGlobalState("DownPayment");
    const [Insurance] = useGlobalState("Insurance");
    const [KMaintenance] = useGlobalState("KMaintenance");
    const [Warranty] = useGlobalState("Warranty");

    const [financeOptions, setFinanceOptions] = useState({});
    const [prices] = useGlobalState("Prices");
    const [kSalesProPrices, setKSalesProPrices] = useState({});
    
    const { pageName } = useParams();
    const modal = useContext(ModalContext);

    const productDetails = useLoaderData();
    const configuration = productDetails.configuration;

    useEffect(() => {
        document.title = "Kubota Tractor - Build My Kubota - " + (productDetails.Tractor.Name);
        setAddOns(Object.values(configuration.configuredItems));
    }, [productDetails]);

    const [addOns] = useGlobalState("AddOns");
    const [selectedPackage, setSelectedPackage] = useState();
    const firstView = Object.keys(productDetails.studios)[0];
    const [view, setView] = useState(productDetails.Tractor.XRSirvLink ? 'Spinner' : firstView);
    const [lpSpin, setLpSpin] = useState("");

    const hasConfigSrc = (productDetails.Tractor.ConfigSirvLink && productDetails.Tractor.ConfigSirvLink.length > 0) ? true : false;

    const tryAddOn = (addOn) => {

        if (!addOn) { // Reset if null/empty
            resetPackage();
            return;
        }
        
        if (addOn.package) {
            if (configuration.hasPackage && configuration.package.id == addOn.package.id) {
                resetPackage();
            }
            else {
                configuration.addPackage(addOn.package);
                setSelectedPackage(addOn.package.id);
                updateConfiguration();
            }
            return;
        }

        // temp state to pass through callbacks
        const inProgressAddOn = {
            rootAddOn: addOn,
            adding: !configuration.hasItemById(addOn.AttachId),
            unhandledRules: [],
        }

        if (configuration.hasPackage) {
            modal.show(
                <>
                    <div className="modal-header">
                        <h2 className="modal-title">Package Conflict</h2>
                    </div>
                    <div className="modal-body config-validation">
                        <span className="validation-headline non-bold">
                            You are selecting an item, <em>{addOn.Description.replace('<br />', ' ').replace('<br/>', ' ')}</em>, that is {inProgressAddOn.adding && 'not'} included in your current
                            package. <strong>Proceeding will remove your package deal.</strong> Do you wish to continue?
                        </span>
                        <div className="selectionContainer groupList bottomBorder packageDecisionGroup">
                            <button className="action-button black" onClick={() => { resetPackage(); startAddOn(inProgressAddOn); }}>Yes, Remove It</button>
                            <button className="action-button" onClick={() => { modal.show() }}>No, Keep My Package</button>
                        </div>
                    </div>
                </>
            );
        }
        else {
            startAddOn(inProgressAddOn);
        }
    }

    const resetPackage = () => {
        configuration.removePackage();
        if (productDetails.Tractor.DefaultTires) {
            configuration.addItem(productDetails.Tractor.DefaultTires);
        }
        setSelectedPackage();
        updateConfiguration();
    }

    const startAddOn = (inProgressAddOn) => {
        if (inProgressAddOn.adding) {
            if (inProgressAddOn.rootAddOn.Manufacturer.replace(/[^a-z0-9]/gi, '') == "LandPride") {
                if (inProgressAddOn.adding && inProgressAddOn.rootAddOn.RotatingImage?.values.length > 0) {
                    setLpSpin(inProgressAddOn.rootAddOn.RotatingImage.values[0]);
                }
                else {
                    setLpSpin('');
                }
            }

            if (inProgressAddOn.rootAddOn.MaxQty > 1) {
                pickQuantity(inProgressAddOn);
            }
            else if (inProgressAddOn.rootAddOn.Manufacturer.replace(/[^a-z0-9]/gi, '') == "LandPride") {
                inProgressAddOn.landPrideData = {};
                landPrideStart(inProgressAddOn);
            }
            else {
                configuration.tryAddItem(inProgressAddOn.rootAddOn, 1, inProgressAddOn.unhandledRules, applyCallback);
                handleNextRule(inProgressAddOn);
            }
        }
        else {
            configuration.tryRemoveItem(inProgressAddOn.rootAddOn, inProgressAddOn.unhandledRules, applyCallback);
            handleNextRule(inProgressAddOn);
        }
    }

    const pickQuantity = (inProgressAddOn) => {
        const addOn = inProgressAddOn.rootAddOn;
        addOn.Quantity = 1;
        modal.show(
            <>
                <div className="modal-header">
                    <h2 className="modal-title">Additional Selection Required</h2>
                </div>
                <div className="modal-body config-validation">
                    <span className="validation-headline">Select quantity (up to {addOn.MaxQty}):</span>
                    <div className="selectionContainer groupList bottomBorder">
                        <AddOn key={addOn.Id} addOnItem={addOn} baseUrl={productDetails.baseUrl}
                            setQuantity={(quantity) => { addOn.Quantity = quantity }} />

                        <button onClick={() => { confirmQuantity(inProgressAddOn) }}>Confirm Selections</button>
                    </div>
                </div>
            </>
        );
    }

    const confirmQuantity = (inProgressAddOn) => {
        if (inProgressAddOn.rootAddOn.Quantity > 0 && inProgressAddOn.rootAddOn.Quantity <= inProgressAddOn.rootAddOn.MaxQty) {
            modal.show();
            if (inProgressAddOn.rootAddOn.Manufacturer.replace(/[^a-z0-9]/gi, '') == "LandPride") {
                inProgressAddOn.landPrideData = {};
                landPrideStart(inProgressAddOn);
            }
            else {
                configuration.tryAddItem(inProgressAddOn.rootAddOn, inProgressAddOn.rootAddOn.Quantity, inProgressAddOn.unhandledRules, applyCallback);
                handleNextRule(inProgressAddOn);
            }
        }
    }

    const landPrideStart = (inProgressAddOn) => {
        modal.show(<div className="land-pride-loading">Configuring {inProgressAddOn.rootAddOn.Description}...<div className="spinner-border text-secondary" role="status"><span className="sr-only"></span></div></div>);
        fetch('/inventory/lpstart?id=' + inProgressAddOn.rootAddOn.Material)
            .then(response => response.json())
            .then(json => {
                if (json.error) {
                    configuration.tryAddItem(inProgressAddOn.rootAddOn, 1, inProgressAddOn.unhandledRules, applyCallback);
                    handleNextRule(inProgressAddOn);
                }
                else {
                    inProgressAddOn.landPrideData = json;
                    if (json.LandPrideConfiguration.IsExecutionComplete) {
                        landPrideFinish(inProgressAddOn);
                    }
                    else {
                        handleLandPrideRule(inProgressAddOn);
                    }
                }
            });
    }

    const landPrideFinish = (inProgressAddOn) => {
        console.log(inProgressAddOn.landPrideData);
        if (!inProgressAddOn.landPrideData.LandPrideAttachment.MsrpPrice) {
            fetch('/inventory/bmkerror', {
                method: 'post',
                body: "No price for LandPride attachment: " + inProgressAddOn.landPrideData.LandPrideAttachment.Material
            });
        }
        configuration.tryAddItem(inProgressAddOn.landPrideData.LandPrideAttachment, 1, inProgressAddOn.unhandledRules, applyCallback);
        handleNextRule(inProgressAddOn);
    }

    const handleLandPrideRule = (inProgressAddOn) => {
        const selectionOptions = inProgressAddOn.landPrideData.LandPrideConfiguration.SelectionOptions;
        const selection = selectionOptions[selectionOptions.length - 1];

        if (!selection?.SelectableValues || selection.SelectableValues.length == 0) {
            landPrideFinish(inProgressAddOn);
        }
        else {
            modal.show(
                <>
                    <div className="modal-header">
                        <h2 className="modal-title">Additional Selection Required</h2>
                    </div>
                    <div className="modal-body config-validation land-pride">
                        <span className="validation-headline">{inProgressAddOn.rootAddOn.Description} requires 1 of the following selections:</span>
                        <span className="validation-headline caption">{selection.Caption.replace('<font color="7F5217">', '').replace('</font>', '')}:</span>
                        <div className="selectionContainer groupList bottomBorder options">
                            {selection.SelectableValues.map((option, index) => (
                                <button key={'lpconfigindex_' + index} onClick={() => landPrideContinue(inProgressAddOn, selection.ID, option.Value)}>{option.Caption.replace(/\(\d+\)/, "")}</button>
                            ))}
                        </div>
                    </div>
                    <button className="action-button cancel" onClick={() => landPrideCancel(inProgressAddOn)}>Cancel</button>
                </>
            );
        }
        
    }

    const landPrideContinue = (inProgressAddOn, option, value) => {
        modal.show(<div className="land-pride-loading">Configuring {inProgressAddOn.rootAddOn.Description}...</div>);

        inProgressAddOn.landPrideData.LandPrideConfiguration.SelectionValues.push({
            ID: option,
            Value: value
        });

        fetch('/inventory/lpcontinue',
            { method: "POST", body: JSON.stringify(inProgressAddOn.landPrideData.LandPrideConfiguration) }
        )
            .then(response => response.json())
            .then(json => {
                inProgressAddOn.landPrideData = json;
                if (json.LandPrideConfiguration.IsExecutionComplete) {
                    landPrideFinish(inProgressAddOn);
                }
                else { 
                    handleLandPrideRule(inProgressAddOn);
                }
            });
    }

    const landPrideCancel = (inProgressAddOn) => {

        fetch('/inventory/lpcancel',
            { method: "POST", body: JSON.stringify(inProgressAddOn.landPrideData.LandPrideConfiguration) }
        );
        cancelCallback();
    }

    const updateConfiguration = (inProgressAddOn) => {
        if (inProgressAddOn?.rootAddOn && inProgressAddOn.adding) {
            window.gtag('event', 'Option Chosen', {
                'Add-On': inProgressAddOn.rootAddOn.Description
            });

            if (inProgressAddOn.rootAddOn.Modelset && view == "Spinner" && hasConfigSrc) {//Only switch if config link present.  Removed for RTVs, 5/7/23 Robert Cheezum & Dan Nordell
                setView(firstView);
            }
        }
        modal.show();
        const selectedAddOns = Object.values(configuration.configuredItems);
        setAddOns(selectedAddOns);
        setSavePopup(true);
    }

    const applyCallback = (action, item) => {
        action.setCaller(item);
    }

    const handleNextRule = (inProgressAddOn) => {
        if (inProgressAddOn.unhandledRules.length == 0) {
            return updateConfiguration(inProgressAddOn);
        }

        // Queue child items
        const action = inProgressAddOn.unhandledRules[0];
        if (action.includeOneOfActions.length > 0) {
            action.includeOneOfActions.forEach((childAction) => {
                inProgressAddOn.unhandledRules.push(childAction);
            });
        }

        // Show selections
        if (action.type === action.actionTypes.IncludesOneOf || action.type === action.actionTypes.Includes || action.type === action.actionTypes.TireCompat) {
            if (action.actionItems.length > 0) {
                modal.show(
                    <>
                        <div className="modal-header">
                            <h2 className="modal-title">Additional Selection Required</h2>
                        </div>
                        <div className="modal-body config-validation">
                            <span className="validation-headline">{inProgressAddOn.rootAddOn.Description} requires {action.combinedQty || 1} of the following items:</span>
                            <div className="selectionContainer groupList bottomBorder">
                                {action.actionItems.map(addOn => (
                                    (action.combinedQty > 1) ? (
                                        <AddOn key={addOn.Id} addOnItem={addOn} baseUrl={productDetails.baseUrl}
                                            setQuantity={(quantity) => { addOn.Quantity = quantity }} />
                                    ) : (
                                        <AddOn key={addOn.Id} addOnItem={addOn} baseUrl={productDetails.baseUrl}
                                            toggle={() => includeCallback(inProgressAddOn, addOn)} />
                                    )
                                ))}

                                {(action.combinedQty > 1) &&
                                    <button onClick={() => includeQuantitiesCallback(inProgressAddOn)}>Confirm Selections</button>
                                }
                            </div>
                        </div>
                    </>
                );
            }
            else {
                inProgressAddOn.unhandledRules.shift();
                handleNextRule(inProgressAddOn);
            }
        }
        // Else show error message
        else {
            let errorMessage = "";
            if (action.type === action.actionTypes.Excludes) {
                errorMessage = "excludes the following item(s):"
            }
            else if (action.type === action.actionTypes.IncludedBy) {
                errorMessage = "is included by the following item(s):"
            }
            else if (action.type === action.actionTypes.IsExcluded) {
                errorMessage = "is excluded by the following item(s):"
            }
            else if (action.type === action.actionTypes.RequiredBy) {
                errorMessage = "is required by the following item(s):"
            }
            else if (action.type === action.actionTypes.Requires) {
                errorMessage = "requires the following item(s):"
            }
            modal.show(
                <>
                    <div className="modal-header">
                        <h2 className="modal-title">Conflict {inProgressAddOn.adding ? "Adding" : "Removing"} Last Choice</h2>
                    </div>
                    <div className="modal-body config-validation">
                        <span className="validation-headline">There was an error {inProgressAddOn.adding ? "adding" : "removing"} {inProgressAddOn.rootAddOn.Description.replace('<br/>', ' ')}</span>
                        <span className="validation-description">{inProgressAddOn.rootAddOn.Description.replace('<br/>', ' ')} {errorMessage}</span>
                        <ul className="selectionContainer groupList bottomBorder">
                            {action.actionItems.map(addOn => (
                                <li key={addOn.Id}>{addOn.Description.replace('<br/>', ' ')}</li>
                            ))}
                        </ul>
                        <button className="action-button cancel" onClick={cancelCallback}>Cancel</button>
                    </div>
                </>
            );
        }
    }

    const cancelCallback = () => {
        modal.show();
        // Reset configuration to last complete state
        configuration.cancelItem();
    }

    const includeCallback = (inProgressAddOn, addOn) => {
        const action = inProgressAddOn.unhandledRules.shift();

        if (inProgressAddOn.adding) {
            configuration.addIncludeItem(addOn, action, inProgressAddOn.rootAddOn, 1);
        }
        else {
            configuration.addItem(addOn, addOn.Quantity || 1);
        }

        if (inProgressAddOn.unhandledRules.length > 0) {
            handleNextRule(inProgressAddOn);
        }
        else {
            if (inProgressAddOn.adding) {
                if (!configuration.hasItemById(inProgressAddOn.rootAddOn.AttachId)) {
                    configuration.addItem(inProgressAddOn.rootAddOn, inProgressAddOn.rootAddOn.Quantity || 1);
                }
            }
            else {
                configuration.removeItem(inProgressAddOn.rootAddOn);
            }
            updateConfiguration(inProgressAddOn);
        }
    }

    const includeQuantitiesCallback = (inProgressAddOn) => {
        const action = inProgressAddOn.unhandledRules[0];
        const totalQty = action.actionItems.reduce((total, item) => (total + item.Quantity), 0);
        if (totalQty != action.combinedQty) {
            return false;
        }

        action.actionItems.forEach(item => {
            if (item.Quantity > 0) {
                configuration.addIncludeItem(item, action, inProgressAddOn.rootAddOn, 1);
            }
        });

        inProgressAddOn.unhandledRules.shift();
        if (inProgressAddOn.unhandledRules.length > 0) {
            handleNextRule(inProgressAddOn);
        }
        else {
            configuration.addItem(inProgressAddOn.rootAddOn);
            updateConfiguration(inProgressAddOn);
        }
    }


    // Load finance options when productDetails changes
    useEffect(() => {
        fetch('/inventory/bmkincentives?material=' + productDetails.Tractor.Material)
        .then(response => response.json())
        .then(json => {
            let newFinanceOptions = { ...financeOptions };
            json.Items?.forEach((item) => {
                if (!newFinanceOptions['cashOffer'] && item.CashProgram) {
                    newFinanceOptions['cashOffer'] = item.CashProgram;
                }

                item.PromoFinancing.forEach((option) => {
                    if (!newFinanceOptions['defaultPromotion']) {
                        newFinanceOptions['defaultPromotion'] = option;
                    }
                    if (!newFinanceOptions[option.Term] || newFinanceOptions[option.Term].Rate > option.Rate) {
                        newFinanceOptions[option.Term] = option;
                    }
                });
                item.StandardFinancing.forEach((option) => {
                    if (!newFinanceOptions[option.Term] || newFinanceOptions[option.Term].Rate > option.Rate) {
                        newFinanceOptions[option.Term] = option;
                    }
                });
            });
            setFinanceOptions(newFinanceOptions);
        });

    }, [productDetails]);

    // Update prices on parameter changes
    useEffect(() => {
        console.log('price parameter changed');
        const selectedAddOns = Object.values(configuration.configuredItems);

        const kubotaAddOns = selectedAddOns.filter(addOn => addOn.Manufacturer.replace(/[^a-z0-9]/gi, '') != "LandPride");
        const addOnPrice = kubotaAddOns.reduce((total, a) => (total + (a.Quantity ? a.Quantity * a.MsrpPrice : a.MsrpPrice)), 0);
        const showAddOnPrice = kubotaAddOns.every(addOn => addOn.MsrpPrice !== 0);

        const landPrideAddOns = selectedAddOns.filter(addOn => addOn.Manufacturer.replace(/[^a-z0-9]/gi, '') == "LandPride");
        const landPridePrice = landPrideAddOns.reduce((total, a) => (total + (a.Quantity ? a.Quantity * a.MsrpPrice : a.MsrpPrice)), 0);
        const showLandPridePrice = landPrideAddOns.every(addOn => addOn.MsrpPrice !== 0);

        let basePrice = productDetails.Tractor.MSRPWithoutTires || 0;
        let price = basePrice + addOnPrice + landPridePrice;

        if (kSalesProPrices.Tax)
            price += kSalesProPrices.Tax;
        if (KMaintenance && kSalesProPrices.KMaintenance)
            price += kSalesProPrices.KMaintenance;
        if (Warranty && kSalesProPrices.Warranty)
            price += kSalesProPrices.Warranty;
        if (kSalesProPrices.Freight)
            price += kSalesProPrices.Freight;
        if (kSalesProPrices.KTAC)
            price += kSalesProPrices.KTAC;
        if (kSalesProPrices.Labor)
            price += kSalesProPrices.Labor;
        if (!isNaN(kSalesProPrices.Misc))
            price = Math.max(price + kSalesProPrices.Misc, 0);

        let downPayment = DownPayment || 0;
        let principal = price - downPayment;

        let payments = (PurchaseType == 'finance' && Term) || 0;
        let interest = financeOptions[payments] ? (financeOptions[payments].Rate / 100 / 12) : 0;
        let apr = financeOptions[payments] ? financeOptions[payments].Rate : 0;

        // Now compute the monthly payment figure, using esoteric math.
        let x = Math.pow(1 + interest, payments);
        let monthly = principal * interest * (x) / (x - 1);
        if (interest === 0) {
            monthly = principal / payments;
        }

        // Check that the result is a finite number. If so, display the results.
        if (isNaN(monthly) || monthly === Number.POSITIVE_INFINITY || monthly === Number.NEGATIVE_INFINITY) {
            monthly = 0;
        }

        const formatPrice = (price, {decimals = false, allowZero = false} = {}) => {
            if (!price && !allowZero) {
                return 'See Dealer for Pricing';
            }
            if (price === '-') {
                return price;
            }

            return (price < 0 ? '-$' : '$') + Math.abs(price).toLocaleString(undefined, {
                minimumFractionDigits: decimals ? 2 : 0,
                maximumFractionDigits: decimals ? 2 : 0
            })
        }

        setPrices({
            basePrice: basePrice,
            formattedBasePrice: formatPrice(basePrice),

            addOnPrice: addOnPrice,
            formattedAddOnPrice: showAddOnPrice ? "+ "+formatPrice(addOnPrice, { allowZero: true }) : formatPrice(0),

            landPridePrice: landPridePrice,
            formattedLandPridePrice: showLandPridePrice ? "+ "+formatPrice(landPridePrice, { allowZero: true }) : formatPrice(0),

            totalPrice: price,
            formattedTotalPrice: formatPrice(price),

            monthlyPayment: monthly,
            formattedMonthlyPayment: formatPrice(monthly, { decimals: false }),

            downPayment: downPayment,
            formattedDownPayment: formatPrice(downPayment),

            apr: apr,

            formattedTax: formatPrice(kSalesProPrices.Tax),
            formattedKMaintenance: formatPrice(kSalesProPrices.KMaintenance),
            formattedWarranty: formatPrice(kSalesProPrices.Warranty),
            formattedFreight: formatPrice(kSalesProPrices.Freight),
            formattedKTAC: formatPrice(kSalesProPrices.KTAC),
            formattedLabor: formatPrice(kSalesProPrices.Labor),
            formattedMisc: formatPrice(kSalesProPrices.Misc),
        });
    }, [addOns, financeOptions, Term, PurchaseType, DownPayment, kSalesProPrices, KMaintenance, Warranty, Insurance]);

    const setKSalesProPrice = (term, val) => {
        setKSalesProPrices({ ...kSalesProPrices, [term]: val });
    }

    if (!productDetails) {
        return (
            < div className="loader" >Loading...</div > // temp
        );
    }

    if (pageName && pageName === 'print') {
        return (
            <FullPrintSummary product={productDetails} prices={prices} selectedAddOns={addOns} offers={financeOptions} quoteid={"Sample Quote Id"} printContents="overview" />
        );
    }

    return (
        <div id="build_details_container" className="wholegoods contentContainer step4 step5 step6 active">
            <div id="build_details_container_image_and_payments" className="buildImagesPaymentsContainer">
                <ProductImages product={productDetails} selectedAddOns={addOns} view={view} setView={setView} latestSpinFile={lpSpin} />
                <ProductPricing product={productDetails} prices={prices} financeOptions={financeOptions} />
            </div>

            
            {(!pageName) ? (
                <ProductSidebar header="Kubota Add-Ons">
                    <p className="selectConfigToChange360 detailsContainerContent bottomBorder">Select a configuration to change the 360 view.</p>
                    <p className="notallvisiblenote detailsContainerContent wholegoodsPanel addonsPanel bottomBorder active"><em className="bi bi-exclamation-circle-fill"></em><span>All configurations may not be visible in the 360 view.</span></p>

                    <AddOnList packages={productDetails.Packages} selectedPackage={selectedPackage} addOnGroups={productDetails.AttachHierarchy.DisplayGroupList} selectedAddOns={addOns} tryAddOn={tryAddOn} baseUrl={productDetails.baseUrl} />

                    {productDetails.AttachHierarchy.LandPrideGroup ? (
                        <Link id="build_details_container_next_button" className="nextButton detailsContainerContent wholegoodsPanel addonsPanel summaryPanel sendquotePanel button orange-button active" to={'land-pride' + window.location.search}>Next : Land Pride Add-Ons</Link>
                    ) : (
                            <Link id="build_details_container_next_button" className="nextButton detailsContainerContent wholegoodsPanel addonsPanel summaryPanel sendquotePanel button orange-button active" to={'summary' + window.location.search}>Next : Summary</Link>
                    )}
                </ProductSidebar>
            ) : null}

            {(pageName === 'land-pride') ? (
                <ProductSidebar header="Land Pride Add-Ons">

                    <AddOnList addOnGroups={productDetails.AttachHierarchy.LandPrideGroup} selectedAddOns={addOns} tryAddOn={tryAddOn} baseUrl={productDetails.baseUrl} />

                    <Link id="build_details_container_next_button" className="nextButton detailsContainerContent wholegoodsPanel addonsPanel summaryPanel sendquotePanel button orange-button active" to={'summary' + window.location.search}>Next : Summary</Link>
                </ProductSidebar>
            ) : null}

            {(pageName === 'summary') ? (
                <ProductSidebar header="Summary">
                    <ProductSummary product={productDetails} prices={prices} selectedAddOns={addOns} offers={financeOptions} setKSalesProPrice={setKSalesProPrice} />
                </ProductSidebar>
            ) : null}

            {(pageName === 'quote') ? (
                <ProductSidebar header="Send Quote">
                    <QuoteForm product={productDetails} />
                </ProductSidebar>
            ) : null}

            {(pageName === 'email') ? (
                <ProductSidebar header="Send Email">
                    <QuoteForm product={productDetails} IKQaction={2} />
                </ProductSidebar>
            ) : null}

        </div>
    );
}

export function ProductSidebar({ header, children }) {

    return (
        <div id="build_details_container_selection" className="detailsContainer">
            <h1 className="buildDetailsHeader detailsContainerContent wholegoodsPanel addonsPanel summaryPanel sendquotePanel quoteSentPanel bottomBorder active">{header}</h1>
            {children}
        </div>
    );
}