export default function Footer() {
    return (
		<footer id="main-footer">
			<div className="sfContentBlock">
				<div className="bottom-nav">
					<img className="mobile-only" src="https://www.kubotausa.com/Content/Images/for-earth-for-life-mobile.png" alt="For Earth, For Life" />
					<ul className="nav global-nav">
						<li>
							<a target="_blank" href="https://www.kubota.com/">Kubota Group</a>
						</li>
						<li>
							<a target="_blank" href="https://www.kubotacreditusa.com/homePrepare.do">
								<img src="https://www.kubotausa.com/Content/Images/Icons/folder-icon.png" alt="Folder Icon" />
							</a>
						</li>
						<li>
							<a target="_blank" href="https://www.kubota.com/">
								<img src="https://www.kubotausa.com/Content/Images/Icons/globe-icon.png" alt="Globe Icon" />
							</a>
						</li>
					</ul>
					<ul className="nav">
						<li>
							<a href="https://www.kubotausa.com/terms-of-use">Terms of Use</a>
						</li>
						<li>
							<a href="https://www.kubotausa.com/privacy-policy">Privacy Policy</a>
						</li>
						<li>
							<a href="https://www.kubotausa.com/site-map">Site Map</a>
						</li>
						<li>
							<a href="https://www.kubotausa.com/about/exercise-your-rights">Exercise Your Rights</a>
						</li>
						<li>
							<a href="https://www.kubotausa.com/about/cookie-notice">Cookie Notice</a>
						</li>
					</ul>
					<div className="copyright">
						<span>
							<strong>Kubota Tractor Corporation</strong>
						</span>
						<span>&copy; 1996 - 2022 Kubota Tractor Corporation</span>
					</div>
				</div>
			</div>
		</footer>
    );
}