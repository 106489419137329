import { useContext, useState } from 'react';
import { Link, useRouteLoaderData } from 'react-router-dom';
import { useGlobalState, setInsurance, setKMaintenance, setWarranty, setTerm, setPurchaseType, setDownPayment } from "./State";
import { ModalContext } from '../context/ModalProvider';

export default function ProductPricing({ product, prices, financeOptions }) {
    const [Term] = useGlobalState("Term");
    const config = useRouteLoaderData("root");

    const [showCalculator, setCalculator] = useState(false);
    const modal = useContext(ModalContext);

    const openCalculator = () => {
        window.gtag('event', 'View Incentives', {
            'Model': product.Tractor.Name
        });

        setCalculator(true);
    }

    return (
        <>
            <div id="build_details_container_details" className="paymentsSharingContainer">
                <a id="adjust_payment_details_link" className="adjustPaymentLink" onClick={openCalculator}>
                    <span>Adjust Your Monthly Payment</span>
                    <em className="bi bi-chevron-down"></em>
                    <span className="corners">&nbsp;</span>
                </a>
                <div id="build_details_container_details_model" className="modelNameSummaryContainer">
                    <span>Model</span>
                    <span className="modelName">{product.Tractor.Name}</span>
                </div>
                <div id="build_details_container_actions_container" className="sharingActionsContainer">
                    <div className="aligner">
                        <button onClick={() => modal.trySave()}><em className="bi bi-heart-fill"></em> Save</button>
                        {config.environment === 'staging' && (
                            <button onClick={() => modal.showShare()}><em className="bi bi-share"></em> Share</button>
                        )}
                    </div>
                </div>
                <div id="build_details_container_details_net_price" className="estimatedNetPriceContainer">
                    <div className="estimatedNetPrice">{prices.formattedTotalPrice }</div>
                    <div className="estimatedNetPriceDisclaimer">Estimated Net Price<sup onClick={() => modal.showDisclaimer(1)}>1</sup></div>
                </div>
                {(prices.formattedMonthlyPayment && prices.monthlyPayment > 0) ? (
                    <>
                        <div className="paymentTermsContainer active">
                            <div className="calculatedPayments"><em className="bi bi-calculator-fill"></em>&nbsp;<span className="paymentAmount">{prices.formattedMonthlyPayment}</span></div>
                            <div className="calculatedPaymentDetails">Per Month for <span className="paymentMonths">{Term}</span> Months Financing<sup onClick={() => modal.showDisclaimer(2)}>2</sup></div>
                        </div>
                        <div className="paymentAPRContainer">
                            <div className="calculatedAPR">{prices.apr}%</div>
                            <div className="calculatedPaymentDetails">Annual Percentage Rate (APR)<sup onClick={() => modal.showDisclaimer(5)}>5</sup></div>
                        </div>
                    </>
                ) :
                    <>
                        <div className="paymentTermsContainer"></div>
                        <div className="paymentAPRContainer"></div>
                    </>}
                <div id="build_details_container_start-over" className="startOverButtonContainer">
                    <a href="/buildmykubota/" id="button-start-over" className="button startOverButton">
                        <em className="bi bi-arrow-clockwise"></em>
                        <span>Start Over</span>
                    </a>
                </div>
                <div id="build_details_container_finish" className="finishButtonContainer">
                    <Link id="button-finish" className="orange-button button showSummary" to={'summary' + window.location.search}>
                        <span>Finish</span>
                    </Link>
                </div>
            </div>

            {showCalculator &&
                <PriceCalculator prices={prices} financeOptions={financeOptions} clickHandler={() => setCalculator(false)} />
            }
        </>
    );
}


function PriceCalculator({ prices, financeOptions, clickHandler }) {
    //todo delete - const [searchParams, setSearchParams] = useSearchParams();
    const modal = useContext(ModalContext);

    const [Insurance] = useGlobalState("Insurance");

    const [KMaintenance] = useGlobalState("KMaintenance");
    const [Warranty] = useGlobalState("Warranty");
    const [Term] = useGlobalState("Term");
    const [PurchaseType] = useGlobalState("PurchaseType");
    const [DownPayment] = useGlobalState("DownPayment");

    let downPayment = '$' + parseInt(DownPayment || 0).toLocaleString();

    const updateDownPayment = (e) => {
        console.log(e.target.value + ' - ' + DownPayment);
        downPayment = parseInt(e.target.value.replace(/[^0-9]+/g, ''));

        if (downPayment >= prices.totalPrice) {
            downPayment = prices.totalPrice;
        }

        e.target.value = downPayment ? '$' + downPayment.toLocaleString() : '';
        setDownPayment(downPayment);
        console.log(e.target.value + ' - ' + DownPayment);
    }

    return (
        <div id="set_payment_options_container" className="paymentOptionsContainer active">
            <a id="hide_payment_details_link" className="hidePaymentLink" onClick={clickHandler}>
                <span>Adjust Your Monthly Payment</span>
                <em className="bi bi-chevron-up"></em>
                <span className="corners">&nbsp;</span>
            </a>
            <div className="paymentOptionsConfiguratorContainer">
                <div className="estimatedNetPriceContainer">
                    <div className="estimatedNetPrice" data-wire-up="EstimatedNetPrice">{prices.formattedTotalPrice}</div>
                    <div className="estimatedNetPriceDisclaimer">Estimated Net Price<sup onClick={() => modal.showDisclaimer(1)}>1</sup></div>
                </div>
                {(prices.formattedMonthlyPayment && prices.monthlyPayment > 0) ? (
                    <>
                        <div className="paymentTermsContainer active">
                            <div className="calculatedPayments"><em className="bi bi-calculator-fill"></em>&nbsp;<span className="paymentAmount">{prices.formattedMonthlyPayment}</span></div>
                            <div className="calculatedPaymentDetails">Per Month for <span className="paymentMonths">{Term}</span> Months Financing<sup onClick={() => modal.showDisclaimer(2)}>2</sup></div>
                        </div>
                        <div className="paymentAPRContainer">
                            <div className="calculatedAPR">{prices.apr}%</div>
                            <div className="calculatedAPRDetails">Annual Percentage Rate (APR)<sup onClick={() => modal.showDisclaimer(5)}>5</sup></div>
                        </div>
                    </>
                ) :
                    <>
                        <div className="paymentTermsContainer"></div>
                        <div className="paymentAPRContainer"></div>
                    </>
                }
                <div className="paymentTermsLoading">
                    <span>Payment Terms Loading...</span>
                </div>
                <div className="paymentOptionsTermsContainer">
                    <span className="heading">Choose Payment Options</span>
                    <div className="paymentOptionsBoxesContainer paymentChoiceContainer">
                        <a className={"paymentOptionBox paymentChoice" + (PurchaseType !== 'finance' ? " active" : "")} onClick={() => setPurchaseType('cash')}><span>&nbsp;</span><strong className="mid">Cash Payment</strong><span>&nbsp;</span></a>
                        <a className={"paymentOptionBox paymentChoice" + (PurchaseType === 'finance' ? " active" : "")} onClick={() => setPurchaseType('finance')}><span>&nbsp;</span><strong className="mid">Finance Purchase</strong><span>&nbsp;</span></a>
                    </div>
                </div>
                <div className="paymentTermNumbersContainer">
                    <div className="heading">Adjust Per Month Payment<sup onClick={() => modal.showDisclaimer(3)}>3</sup>
                        <span>Check your local dealer for additional incentives.</span>
                    </div>
                    <div className="paymentOptionsBoxesContainer">
                        {Object.keys(financeOptions).map(option => ((parseInt(option) > 0) &&
                            <a key={option} className={"paymentOptionBox financeTermsChoice" + (Term === option ? " active" : "")} onClick={() => setTerm(option)}><strong>{option} Months</strong><span className="mid">{financeOptions[option].Rate}% APR</span><span>{(financeOptions[option].MinimumDownPaymentPercent && financeOptions[option].MinimumDownPaymentPercent == 0) ? "$0 Down" : financeOptions[option].MinimumDownPaymentPercent + "% Down"}</span></a>
                        ))}
                    </div>
                </div>

                <div className="paymentCheckboxContainers">
                    <a className="questionOrangeProtection paymentQuestionContainer" onClick={() => setWarranty((Warranty) ? false : true)}>
                        <em className={Warranty ? " bi bi-check-square-fill orange" : "bi bi-square"} ></em>
                        <span>Contact Me about Orange Protection<sup onClick={() => modal.showDisclaimer(7)}>7</sup></span>
                    </a>
                    <a className="questionKTAC paymentQuestionContainer" onClick={() => setInsurance((Insurance) ? false : true)}>
                        <em className={Insurance ? " bi bi-check-square-fill orange" : "bi bi-square"} ></em>
                        <span>Physical Damage Insurance through KTAC<sup onClick={() => modal.showDisclaimer(6)}>6</sup></span>
                    </a>
                </div>

                <div className="paymentDownContainer">
                    <span className="heading">Down Payment Amount<sup onClick={() => modal.showDisclaimer(4)}>4</sup></span>
                    <div className="paymentOptionsBoxesContainer downPaymentBoxesContainer">
                        <input type="tel" id="downpaymentAmount" value={downPayment}  onChange={updateDownPayment} />
                        <button id="recalcPayment" className="ui-button black-button">
                            <span>Calculate</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}