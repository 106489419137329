import { createBrowserRouter, redirect } from "react-router-dom";
import BuildMyKubota from '../container/BuildMyKubota';
import ProductSelector from '../selector/ProductSelector';
import ProductDetails from '../configurator/ProductDetails';

async function sendError(message, returnToHome = false) {
    fetch('/inventory/bmkerror', {
        method: 'post',
        body: message
    });
    if (returnToHome) {
        return redirect("/");
    }
    return null;
}

async function loadProducts(id) {
    if (id === "") {
        return redirect("/");
    }
    const response = await fetch('/inventory/bmkproducts?id=' + id);
    try {
        const json = await response.json();
        if (!json.ProductGroups) {
            return sendError('Invalid data from /inventory/bmkproducts?id=' + id, true);
        }
        return json;
    }
    catch (e) {
        return sendError('Unable to parse reponse from /inventory/bmkproducts?id=' + id, id !== 0);
    }
};

async function loadConfig() {
    const configResponse = await fetch('/buildmykubota/config.json');
    const configJson = await configResponse.json();

    const bannerResponse = await fetch('/buildmykubota/banners.json');
    const bannerJson = await bannerResponse.json();

    configJson.banners = bannerJson;
    return configJson;
};

async function loadProductDetails(id, request) {
    const productResponse = await fetch('/inventory/bmktractor?id=' + id);
    const productJson = await productResponse.json();

    if (!productJson.Tractor) {
        return sendError('Invalid data from /inventory/bmktractor?id=' + id, true);
    }

    if (!productJson.Tractor.MSRPWithoutTires) {
        sendError('No pricing from /inventory/bmktractor?id=' + id);
    }

    if (!productJson.Tractor.ImagePath) {
        sendError('No images from /inventory/bmktractor?id=' + id);
    }

    productJson.studios = {};
    productJson.imageMappingData = [];

    const equipResponse = await fetch('/inventory/bmkequipment?id=' + id);
    const equipment = await equipResponse.json();
    productJson.equipmentHtml = equipment.replaceAll('/images/', 'https://apps.kubotausa.com/images/');

    if (productJson.Tractor.ConfigSirvLink) {
        const configSirvPath = productJson.Tractor.ConfigSirvLink.replace('keyshot-configurator.html', '');

        const configResponse = await fetch(configSirvPath + 'keyshot-configurator-modeldata.js');
        const configJs = await configResponse.text();

        if (configJs.indexOf('model') == 0) {
            eval('window.' + configJs);
            window.model.studios.forEach((studio) => {
                productJson.studios[studio.name] = { icon: configSirvPath + 'icons/' + encodeURIComponent(studio.icon), id: studio.id };
            });
        }

        const imgResponse = await fetch(configSirvPath + 'keyshot-configurator-imagemapping.js');
        const imgJs = await imgResponse.text();

        if (imgJs.indexOf('imagemappingdata') == 0) {
            eval('window.' + imgJs);
            productJson.imageMappingData = window.imagemappingdata;
        }
    }

    productJson.configuration = new window.ProductConfiguration(productJson.Tractor, productJson.RuleList);
    if (productJson.Tractor.DefaultTires) {
        productJson.configuration.addItem(productJson.Tractor.DefaultTires);
    }

    const buildId = new URL(request.url).searchParams.get('build-id');
    if (buildId) {
        const savedBuildResponse = await fetch('/inventory/bmkloadbuild?id=' + buildId);
        const savedBuild = await savedBuildResponse.json();

        productJson.configuration.ConfiguredItem.ChildItems = window._.cloneDeep(JSON.parse(savedBuild.ConfiguredItems));

        console.log(productJson.configuration);
    }
    const configSirvLink = productJson.Tractor.ConfigSirvLink || '';
    productJson.baseUrl = configSirvLink.replace('keyshot-configurator.html', '');

    //productJson.AttachHierarchy.LandPrideGroup = null; // temporarily disable landpride addons

    return productJson;
};

const Router = createBrowserRouter([{
    path: "/",
    element: <BuildMyKubota />,
    id: "root",
    loader: loadConfig,
    children: [
        {
            path: "",
            element: <ProductSelector />,
            loader: () => {
                return loadProducts(0);
            }
        },
        {
            path: ":productSlug",
            element: <ProductSelector />,
            loader: ({ params }) => {
                const id = params.productSlug.substring(0, params.productSlug.indexOf('-'));
                return loadProducts(id);
            }
        },
        {
            path: ":productSlug/:seriesSlug",
            element: <ProductSelector />,
            loader: ({ params }) => {
                const id = params.seriesSlug.substring(0, params.seriesSlug.indexOf('-'));
                return loadProducts(id);
            }
        },
        {
            path: ":productSlug/:seriesSlug/:modelName",
            element: <ProductDetails />,
            id: "productDetails",
            loader: ({ params, request }) => {
                return loadProductDetails(params.modelName, request);
            },
            children: [
                { path: ":pageName" }
            ]
        }
    ]
}], {basename: "/buildmykubota/"});

export default Router;