import { useState, useContext } from 'react';
import { useGlobalState } from "./State";

export default function FullPrintSummary({ product, prices, selectedAddOns, quote, quoteid, printContents }) {
    const [Insurance] = useGlobalState("Insurance");
    const [KMaintenance] = useGlobalState("KMaintenance");
    const [Warranty] = useGlobalState("Warranty");
    const [PurchaseType] = useGlobalState("PurchaseType");
    const [Term] = useGlobalState("Term");
    const [SalesPerson] = useGlobalState("SalesPerson");

    let summaryImage = product.Tractor.ImagePath;

    const modelSets = [product.Tractor.Modelset];
    const defaultView = Object.keys(product.studios)[0];
    Object.values(product.configuration.configuredItems).forEach((item) => {
        if (item.Modelset) {
            modelSets.push(item.Modelset);
        }
    });
    const matchingImage = product.imageMappingData.find((imd) => {
        if (!product.studios[defaultView] || imd.studio !== product.studios[defaultView].id) {
            return false;
        }
        if (imd.modelsets.length !== modelSets.length) {
            return false;
        }
        if (!imd.modelsets.every((ms) => modelSets.includes(ms))) {
            return false;
        }
        return true;
    });
    if (matchingImage) {
        summaryImage = product.baseUrl + 'images/' + encodeURIComponent(matchingImage.image);
    }

    return (
        <div className={"wholegoods contentContainer active printSummary " + printContents}>
            <div className="detailsContainer">
                <div className="right-list summary">
                    <div className="printHeader">
                        <div className="printHeadersLogo">
                            <img src="https://shop.kubotausa.com/assets/kubotatractor/images/headerlogo-1000.png" alt="Kubota Tractor Logo" className="printLogo" />
                        </div>
                        <div className="printHeadersModelName">
                            <span className="nameContainer">{(quote && quote.customer && quote.customer.firstName) ? quote.customer.firstName + ' ' + quote.customer.lastName + ' Build' : 'Your Build'}</span>
                            <strong className="nameModelContainer">{product.Tractor.Material}</strong>
                        </div>
                        <div className="printHeadersDetails">
                            <span className="printHeadersDetailsText">Date: {getCurrentDate('/')}</span>
                            <span className="printHeadersDetailsText">More Info</span>
                            <span className="printHeadersDetailsText">Web Quote <span className="quoteIdHolder">{quoteid}</span></span>
                        </div>
                    </div>

                    <div className="summaryHeader">
                        <img src={summaryImage} className="summaryImage" alt="Your Custom Build" />
                        {selectedAddOns.filter(addOn => addOn.Manufacturer == "LandPride").map((addOn, index) => (
                            <PrintAddOnLandPrideImage key={'addOnImage_' + index} origTractor={product} addOnItem={addOn.data} />
                        ))}

                        
                            <div className="summaryInfo">
                                <div className="summaryHeadline">Build Summary</div>

                                {quote?.customer && (
                                    <>
                                        {(quote.customer.firstName || quote.customer.lastName) && (
                                            <div className="summaryCustomerInfo">{quote.customer.firstName} {quote.customer.lastName}</div>
                                        )}

                                        <div className="summaryCustomerInfo">
                                            {quote.customer.city} {(quote.customer.city && quote.customer.state) && (<>, </>)}
                                            {quote.customer.state}
                                            {quote.customer.ZIP}
                                        </div>
                                        {(quote.customer.phone) && (
                                            <div className="summaryCustomerInfo">Phone: {quote.customer.phone}</div>
                                        )}
                                        {(quote.customer.email) && (
                                            <div className="summaryCustomerInfo">Email: {quote.customer.email}</div>
                                        )}
                                    </>
                                )}
                            </div>
                        
                        

                        <div className="estimatedNetPriceContainer">
                            <div className="estimatedNetPrice">{prices.formattedTotalPrice}</div>
                            <div className="estimatedNetPriceDisclaimer">Estimated Net Price</div>
                        </div>

                        {(prices.formattedMonthlyPayment && prices.formattedMonthlyPayment.length > 0 && prices.formattedMonthlyPayment != "See Dealer for Pricing") ? (
                            <>
                                <div className="paymentTermsContainer">
                                    <div className="paymentAmount">{prices.formattedMonthlyPayment}</div>
                                    <div className="calculatedPaymentDetails">
                                        Per Month for <span className="paymentMonths">{Term}</span> Months Financing
                                    </div>
                                </div>
                                
                            </>
                        ) : ('')}
                    </div>

                    <div className="printEquipment" dangerouslySetInnerHTML={{ __html: product.equipmentHtml }} />

                    {printContents === "detailed" && (
                        <>
                            <PrintBaseSummary header='Standard Equipment Included' material={product.Tractor.Material + ' ' + product.Tractor.Description} selectedAddOns={Object.values(product.configuration.includedAttachments)} linkToPage='.' baseUrl={product.baseUrl} baseModelPrice={prices.formattedBasePrice} />

                            <PrintAddOnSummary header='Kubota Add-Ons' linkToPage='.' selectedAddOns={selectedAddOns.filter(addOn => addOn.Manufacturer != "LandPride")} defaultAddOns={Object.values(product.configuration.includedAttachments)}  baseUrl={product.baseUrl} />
                            <PrintAddOnSummary header='Land Pride Add-Ons' linkToPage='land-pride' selectedAddOns={selectedAddOns.filter(addOn => addOn.Manufacturer == "LandPride")} defaultAddOns={Object.values(product.configuration.includedAttachments)} baseUrl={product.baseUrl} />
                            <PrintPhysicalDamageInsurancePanel />
                            <PrintKMaintenancePanel />
                            <PrintWarrantyPanel />
                            <PrintOffersPanel />
                        </>
                    )}

                    <div id="build_details_container_pricing_summary" className="detailsContainerContent summaryPanel bottomBorder">

                        {printContents === "detailed" && (
                            <>
                                <div className="groupListHeader"><span>Pricing Summary</span></div>
                                <div className="pricingDetailsContainer small">
                                    <div className="itemContainer">
                                        <div className="key">Base Model</div><div className="value"><span>{prices.formattedBasePrice}</span></div>
                                        <div className="key">Kubota Add-Ons</div><div className="value"><span>{prices.formattedAddOnPrice}</span></div>
                                        <div className="key">Land Pride Add-Ons</div><div className="value"><span>{prices.formattedLandPridePrice}</span></div>

                                        {(prices.formattedDownPayment != 'See Dealer for Pricing') ?
                                            <>
                                                <div className="key">Down Payment</div><div className="value">-&nbsp;<span>{prices.formattedDownPayment}</span></div>
                                            </> :
                                            ""
                                        }
                                        <div className="key">Tax</div><div className="value">{prices.formattedTax}</div>
                                        {Insurance &&
                                            <><div className="key">Physical Damage Insurance</div><div className="value">{prices.formattedInsurance}</div></>
                                        }
                                        {KMaintenance &&
                                            <><div className="key">K-Maintenance</div><div className="value">{prices.formattedKMaintenance}</div></>
                                        }
                                        {Warranty &&
                                            <><div className="key">Orange Protection</div><div className="value">{prices.formattedWarranty}</div></>
                                        }
                                        <div className="key">Freight</div><div className="value">{prices.formattedFreight}</div>
                                        <div className="key">KTAC PDI</div><div className="value">{prices.formattedKTAC}</div>
                                        <div className="key">Labor/Assembly</div><div className="value">{prices.formattedLabor}</div>
                                        {SalesPerson &&
                                            <>
                                                <div className="key">Misc. Adjustments</div><div className="value">{prices.formattedMisc}</div>
                                            </>
                                        }
                                        <div className="key">Total MSRP</div><div className="value"><span>{prices.formattedTotalPrice}</span></div>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="pricingDetailsContainer estimatedNetPricingBox large">
                            <div className="itemContainer">
                                <div className="key"><span>Estimated Net Price</span></div><div className="value"><span>{prices.formattedTotalPrice}</span></div>
                            </div>
                        </div>
                        <div className="pricingDisclaimerBox print">
                            This List Price configuration program is for informational purposes only. Price on this sales quote is an estimate and is subject to being increased. Payment assumes the MSRP of your selected equipment and accessories, the length of term you have selected and any applicable special A.P.R. program. Not Included: *Taxes, shipping; handling, surcharges, assembly charges, destination, freight and/or delivery charges.<br /><br />
                            <strong>Not all models are available to order. Contact your dealer for available inventory stock or future availability.</strong>
                        </div>
                        {(PurchaseType === 'finance' && Term) &&
                            <div className="pricingDetailsContainer large">
                                <div className="itemContainer">
                                    <div className="key"><span>Estimated Monthly Payment</span></div><div className="value"><span>{prices.formattedMonthlyPayment}</span></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export function PrintBaseSummary({ selectedAddOns, header, material, linkToPage, baseUrl, baseModelPrice }) {

    if (!selectedAddOns) {
        return null;
    }

    return (
        <div id="build_details_container_selections_wholegoods" className="detailsContainerContent summaryPanel bottomBorder active">
            <div className="groupListHeader"><span>{header}</span></div>
            <div className="materialDescription">{material}</div>
            {selectedAddOns.map((item) => (
                <PrintAddOn key={item.Id} addOnItem={item} active={selectedAddOns.find(i => (i.Id == item.Id))} baseUrl={baseUrl} />
            ))}
            <div className="baseModelPriceContainer">
                <span className="label">                Base Model Price</span>
                <span className="value">{baseModelPrice}</span>
            </div>
        </div>

    );
}

export function PrintAddOnSummary({ selectedAddOns, defaultAddOns, header, linkToPage, baseUrl }) {
    //const [searchParams, setSearchParams] = useSearchParams();
    //const addOns = searchParams.get('addons') ? searchParams.get('addons').split('|') : [];

    selectedAddOns = selectedAddOns.filter(addOn => !defaultAddOns.find(def => def.Material == addOn.Material));

    if (selectedAddOns.length == 0) {
        return null;
    }

    return (
        <div id="build_details_container_selections_wholegoods" className="detailsContainerContent summaryPanel bottomBorder active">
            <div className="groupListHeader"><span>{header}</span></div>
            {selectedAddOns.map((item) => (
                <PrintAddOn key={item.Id} addOnItem={item} active={selectedAddOns.find(i => (i.Id == item.Id))} baseUrl={baseUrl} />
            ))}
        </div>

    );
}

export function PrintAddOn({ addOnItem, active, toggle = null, setQuantity = null, baseUrl, allowMultiple }) {
    const price = addOnItem.MsrpPrice ? '$' + addOnItem.MsrpPrice.toLocaleString() : 'See Dealer for Pricing';

    const [quantity, setInternalQuantity] = useState(addOnItem.Quantity || 0);

    var thumbnail = false;
    if (addOnItem.ImagePath != undefined && addOnItem.ImagePath != null && addOnItem.ImagePath.length) {
        thumbnail = addOnItem.ImagePath;
    }
    else if (addOnItem.PrimaryProductImage != undefined && addOnItem.PrimaryProductImage.images['300px_uri'] != null) {
        thumbnail = addOnItem.PrimaryProductImage.images['300px_uri'];
    }

    if (!active && !toggle && !setQuantity) {
        return null;
    }

    return (
        <div className="groupListOptionGroupContainer">
            <div className={"attachItemContainer" + ((!thumbnail) ? " nothumbnail" : "") + ((active) ? " active" : "")}>

                {thumbnail &&
                    <>
                        <em className="bi bi-check-circle-fill orange active-item"></em>
                        <div className="wholegoodsItemImage">
                            <img src={thumbnail} alt={addOnItem.AttachId} />
                        </div>
                    </>
                }
                <div className="wholegoodsItemDetailsContainer">
                    {!thumbnail && toggle &&
                        <em className={allowMultiple ? (active ? "bi bi-check-square-fill orange" : "bi bi-square")
                            : (active ? "bi bi-check-circle-fill orange" : "bi bi-circle")}></em>
                    }
                    <div className="wholegoodsItemDetailsNameContainer">
                        {addOnItem.Description.split(/<br\s*\/?>/i).map(desc => (
                            <div key={'wholegoodsItemDetailsName_' + desc.replace('\'', '')} className="wholegoodsItemDetailsName">{desc}</div>
                        ))}

                        <div className="wholegoodsItemPrice ">
                            {price}
                            {active && !setQuantity && price && addOnItem.Quantity > 1 && (' x ' + addOnItem.Quantity)}
                        </div>

                    </div>
                    <div className="wholegoodsItemAction">
                        <div className="printPartNum">{addOnItem.DisplayMaterial}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function PrintAddOnImage({ addOnItem }) {
    var thumbnail = false;
    if (addOnItem.ImagePath != undefined && addOnItem.ImagePath != null && addOnItem.ImagePath.length) {
        thumbnail = addOnItem.ImagePath;
    }
    else if (addOnItem.PrimaryProductImage != undefined && addOnItem.PrimaryProductImage.images['300px_uri'] != null) {
        thumbnail = addOnItem.PrimaryProductImage.images['300px_uri'];
    }

    return (
        (thumbnail) ? (
            <img src={thumbnail} alt={addOnItem.AttachId} />
        ) : ('')
    );
}

export function PrintPhysicalDamageInsurancePanel({ prices }) {
    const [Insurance] = useGlobalState("Insurance");
    
    return (
        (Insurance) ? (
        <div id="build_details_container_physical_damage_insurance" className="detailsContainerContent summaryPanel right-list bottomBorder">
            <div className="groupListHeader"><span>Physical Damage Insurance</span></div>
            <div className="selectionContainer">
                <div className={"attachItemContainer ktacItem specialOffersItem" + (Insurance ? " active" : "")}>
                    <em className="bi bi-check-circle-fill orange active-item"></em>
                    <div className="wholegoodsItemImage centerSmall">
                        <img src="https://shop.kubotausa.com/assets/kubotatractor/images/cogwheel-icon.svg" alt="Physical Damage Insurance" />
                    </div>
                    <div className="wholegoodsItemDetailsContainer">
                        <div className="wholegoodsItemDetailsNameContainer">
                            <div className="wholegoodsItemDetailsName">
                                <span>Physical Damage Insurance through KTAC</span>
                            </div>
                            <div className="wholegoodsItemPrice ">
                                <span className="offerDetails">SEE DEALER FOR PRICING</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        ) : ('')
    );
}

export function PrintKMaintenancePanel() {
    const [KMaintenance] = useGlobalState("KMaintenance");

    return (
        (KMaintenance) ? (
            <div id="build_details_container_kmaintenance" className="detailsContainerContent summaryPanel right-list bottomBorder">
                <div className="groupListHeader"><span>K-Maintenance</span></div>
                <div className="selectionContainer">
                    <div className={"attachItemContainer warrantyItem specialOffersItem" + (KMaintenance ? " active" : "")}>
                        <em className="bi bi-check-circle-fill orange active-item"></em>
                        <div className="wholegoodsItemImage centerSmall">
                            <img src="https://shop.kubotausa.com/assets/kubotatractor/images/shield-icon.svg" alt="Discuss K-Maintenance" />
                        </div>
                        <div className="wholegoodsItemDetailsContainer">
                            <div className="wholegoodsItemDetailsNameContainer">
                                <div className="wholegoodsItemDetailsName">
                                    <span>Maintenance Packages available</span>
                                </div>
                                <div className="wholegoodsItemPrice ">
                                    <span className="offerDetails">SEE DEALER FOR OPTIONS</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : ('')
    );
}

export function PrintWarrantyPanel() {
    const [Warranty] = useGlobalState("Warranty");

    return (
        (Warranty) ? (
        <div id="build_details_container_warranty" className="detailsContainerContent summaryPanel right-list bottomBorder">
            <div className="groupListHeader"><span>Orange Protection (Extended Warranty) Offers</span></div>
            <div className="selectionContainer">
                <div className={"attachItemContainer warrantyItem specialOffersItem" + (Warranty ? " active" : "")}>
                    <em className="bi bi-check-circle-fill orange active-item"></em>
                    <div className="wholegoodsItemImage centerSmall">
                        <img src="https://shop.kubotausa.com/assets/kubotatractor/images/shield-icon.svg" alt="Discuss Warranty" />
                    </div>
                    <div className="wholegoodsItemDetailsContainer">
                        <div className="wholegoodsItemDetailsNameContainer">
                            <div className="wholegoodsItemDetailsName">
                                <span>Kubota-backed extended protection solution</span>
                            </div>
                            <div className="wholegoodsItemPrice ">
                                <span className="offerDetails">SEE DEALER FOR OPTIONS</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : ('')
    );
}

export function PrintOffersPanel({ offers }) {
    return ('');
}

export function getCurrentDate(separator = '') {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${month < 10 ? `0${month}` : `${month}`}${separator}${date}${separator}${year}`
}

export function PrintAddOnLandPrideImage({ addOnItem, origTractor }) {
    const getLandPrideThumbUrl = (addOnItem) => {
        let thumbnail = 'unknown';

        if (addOnItem && addOnItem != null && addOnItem.Id) {

            origTractor.AttachHierarchy.LandPrideGroup.OptionGroupList.map(group => (
                thumbnail = (group.AttachmentList.filter(a => a.Id == addOnItem.Id).length > 0) ? group.AttachmentList.filter(a => a.Id == addOnItem.Id)[0].PrimaryProductImage.images['300px_uri'] : thumbnail
            ));
        }

        return thumbnail;
    }

    var thumbnail = getLandPrideThumbUrl(addOnItem);

    return (
        (thumbnail != "unknown") ? (
            <img src={thumbnail} alt={addOnItem.AttachId} />
        ) : ('')
    );
}