import { createGlobalState } from 'react-hooks-global-state';
import Cookies from 'universal-cookie';

const params = new URLSearchParams(window.location.search);
const cookies = new Cookies();

let userCookie = cookies.get('bmkUc');

let nav = params.get('nav');
if (nav) {
    cookies.set("nav", nav);
}
else {
    nav = cookies.get("nav");
}

let dealerGroup = params.get('dg');
if (dealerGroup) {
    cookies.set("dealerGroup", dealerGroup);
}
else {
    dealerGroup = cookies.get("dealerGroup");
}

let dealerNum = params.get('dn');
if (dealerNum) {
    let testDealers = ['95995', '99999'];
    if (testDealers.includes(dealerNum))
        dealerNum = '11111';

    cookies.set("dealerNumber", dealerNum);
}
else {
    dealerNum = cookies.get("dealerNumber");
}

let salesPerson = params.get('sp');
if (salesPerson) {
    cookies.set("salesPerson", salesPerson);
}
else {
    salesPerson = cookies.get("salesPerson");
}

let changeApi = (window.bmkApi == 'change');
cookies.set("bmkApi", (changeApi ? 'change' : 'production'), {path: '/'});



fetch('/inventory/checkbmkdraft')
    .then(response => response.json())
    .then(json => {
        setGlobalState('DraftData', json.draftmode);
    });

const initialState = {
    PurchaseType: 'cash', // cash|finance
    Term: 0, //0|12|24|36|48|60|72|84
    DownPayment: 0, // 0+
    KMaintenance: false, //false|true
    Warranty: false, //false|true 
    Insurance: false, //false|true 
    ZipCode: '', //string
    DealerNumber: dealerNum, //string, 6 characters
    DealerGroup: dealerGroup, //string, 6 characters
    SalesPerson: salesPerson, //string, 6 characters
    DraftData: false,
    ChangeAPI: changeApi,
    SavePopup: false,
    AddOns: [],
    Prices: [],
    User: userCookie,
    NavigationMode: nav
};

const {
    GlobalStateProvider,
    setGlobalState,
    getGlobalState,
    useGlobalState
} = createGlobalState(initialState);

export const setPurchaseType = PurchaseType => {
    //setLSItem('PurchaseType', PurchaseType);
    setGlobalState('PurchaseType', PurchaseType);
};

export const setTerm = Term => {
    //setLSItem('Term', Term);
    setGlobalState('Term', Term);
};

export const setDownPayment = DownPayment => {
    //setLSItem('DownPayment', DownPayment);
    setGlobalState('DownPayment', DownPayment);
};

export const setAddOns = AddOns => {
    setGlobalState('AddOns', AddOns);
};

export const setPrices = Prices => {
    setGlobalState('Prices', Prices);
};

export const setKMaintenance = KMaintenance => {
    //setLSItem('KMaintenance', KMaintenance);
    setGlobalState('KMaintenance', KMaintenance);
};

export const setWarranty = Warranty => {
    //setLSItem('Warranty', Warranty);
    setGlobalState('Warranty', Warranty);
};

export const setInsurance = Insurance => {
    //setLSItem('Insurance', Insurance);
    setGlobalState('Insurance', Insurance);
};

export const setZipCode = ZipCode => {
    //setLSItem('ZipCode', ZipCode);
    setGlobalState('ZipCode', ZipCode);
};

export const setDealerNumber = DealerNumber => {
    //setLSItem('DealerNumber', DealerNumber);
    setGlobalState('DealerNumber', DealerNumber);
};

export const setDealerGroup = DealerGroup => {
    //setLSItem('DealerNumber', DealerNumber);
    setGlobalState('DealerGroup', DealerGroup);
    if (DealerGroup) {
        cookies.set("dealerGroup", DealerGroup);
    }
    else {
        cookies.remove("dealerGroup");
    }
};

export const setSavePopup = showPopup => {
    setGlobalState('SavePopup', showPopup);
};
export const getSavePopup = () => getGlobalState('SavePopup');

function setLSItem(name, object) {
    try {
        window.localStorage.setItem(name, JSON.stringify(object));
    } catch (err) {
        console.error(err);
    }
}

function getLSItem(name) {
    try {
        const item = window.localStorage.getItem(name);
        return JSON.parse(item);
    } catch (err) {
        return null;
    }
}

export { GlobalStateProvider, useGlobalState };
