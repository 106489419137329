import { useState, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ModalContext } from '../context/ModalProvider';
import ReactToPrint from 'react-to-print';

export default function ProductImages({ product, view, setView, latestSpinFile, selectedAddOns }) {
    const modal = useContext(ModalContext);
    const { pageName } = useParams();

    const spinSrc = product.Tractor.XRSirvLink || '';
    const spinThumbnail = spinSrc.replace(/\/[^\/]*$/, '/0_0.jpg');

    const printableSpecsRef = useRef();

    const modelSets = [product.Tractor.Modelset];
    Object.values(product.configuration.configuredItems).forEach((item) => {
        if (item.Modelset) {
            modelSets.push(item.Modelset);
        }
    });

    let imageErrors = [];
    if (spinSrc == '') {
        imageErrors.push('No spin file found (missing/incorrect Tractor.XRSirvLink)');
    }

    // Find current image by modelset + selected view
    let currentImageUrl = '';
    let overlayComingSoon = false;
    if (product.imageMappingData.length > 0) {
        const matchingImage = product.imageMappingData.find((imd) => {
            if (!product.studios[view] || imd.studio !== product.studios[view].id) {
                return false;
            }
            if (imd.modelsets.length !== modelSets.length) {
                return false;
            }
            if (!imd.modelsets.every((ms) => modelSets.includes(ms))) {
                return false;
            }
            return true;
        });
        if (matchingImage) {
            currentImageUrl = product.baseUrl + 'images/' + encodeURIComponent(matchingImage.image);
            product.defaultImage = currentImageUrl;
            imageErrors.push('Image mapping data searched for these selected modelsets (' + modelSets.join(', ') + ')');
        }
        else {
            currentImageUrl = (product.Tractor.ImagePath.length > 0) ? product.Tractor.ImagePath : '/buildmykubota/assets/kubotatractor/kubota-image-coming-soon.png';
            overlayComingSoon = true;
            imageErrors.push('Image mapping data does not have a match for the selected modelsets (' + modelSets.join(', ') + ')');
        }
    } else {
        imageErrors.push('No studios or image mapping data found (missing/incorrect Tractor.ConfigSirvLink)');
    }

    if (product.Tractor.ImagePath.length == 0) {
        imageErrors.push('No default image found (missing/incorrect Tractor.ImagePath)');
    }

    const showImageErrors = () => {
        modal.show(
            <div className="image-errors">
                <h1>Image Debugging</h1>
                <ul>
                    {imageErrors.map(error => (
                        <li key={'image-error-' + error.replace('\'', '')}>{error}</li>   
                    ))}
                </ul>
            </div>
        );
    }

    const showSpecs = () => {
        modal.show(
            <div className="specsModal">
                <h1>
                    {product.Tractor.Name} - Standard Equipment
                </h1>
                <ReactToPrint
                    trigger={() => (<button className="imageSpecsButton printSpecsButton"><em className="bi bi-printer"></em> Print</button>)}
                    content={() => (printableSpecsRef.current)}
                />
                <div className="specsModalTable" dangerouslySetInnerHTML={{ __html: product.equipmentHtml }} ref={printableSpecsRef} />
            </div>
        );
    }

    if (pageName === 'land-pride') {
        let imageContent = (<>
            <div className="subImageContainer">
                <img src="land-pride-temp.jpg" className="active" />
            </div>
            <button className="show-image-errors" onClick={showImageErrors}><i className="bi bi-info-circle"></i></button>
        </>);

        return (
            <div id="build_details_container_main_image" className="mainImageContainer active">
                {product.equipmentHtml &&
                    <button className="imageSpecsButton" onClick={showSpecs}><em className="bi bi-card-checklist"></em> View Specs</button>
                }
                {(latestSpinFile) ? (
                    <div className="subImageContainer active">
                        <div style={{ maxWidth: '3200px', maxHeight: '2560px' }}>
                            <div style={{ left: '0px', width: '100%', height: '0px', paddingBottom: '80%', overflow: 'hidden' }}>
                                <iframe src={latestSpinFile} allowFullScreen={true} style={{ position: 'absolute', top: '0px', left: '0px', height: '100%', width: '1px', minWidth: '100%', width: '100%' }} frameBorder="0" scrolling="no">
                                </iframe>
                            </div>
                        </div>
                    </div>
                ) : (
                        <div className="subImageContainer">
                            <img src="/buildmykubota/assets/kubotatractor/land-pride_choose.png" className="active not-available" />
                    </div>
                )}
            </div>
        );
    }

    if (pageName === 'summary' || pageName === 'quote') {
        if (!currentImageUrl || currentImageUrl.length <= 0)
            currentImageUrl = product.Tractor.ImagePath;

        let lpAddOns = selectedAddOns.filter(addOn => addOn.Manufacturer == "LandPride");

        return (
            <>
                <div id="build_details_container_main_image" className={(lpAddOns && lpAddOns.length > 0) ? "mainImageContainer summary active" : "mainImageContainer active"}>
                    {product.equipmentHtml &&
                        <button className="imageSpecsButton" onClick={showSpecs}><em className="bi bi-card-checklist"></em> View Specs</button>
                    }
                    {(view === 'Spinner') ? (

                    <div className="subImageContainer active">
                            <div style={{ maxWidth: '3200px', maxHeight: '2560px' }}>
                                <div style={{ left: '0px', width: '100%', height: '0px', paddingBottom: '80%', overflow: 'hidden' }}>
                                        <iframe src={spinSrc} allowFullScreen={true} style={{ position: 'absolute', top: '0px', left: '0px', height: '100%', width: '1px', minWidth: '100%', width: '100%' }} frameBorder="0" scrolling="no">
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={(overlayComingSoon) ? "coming-soon subImageContainer" : "subImageContainer"}>
                            <img src={currentImageUrl} className="active" />
                            {overlayComingSoon ? (
                                <span className="noaddons coming">Image Not Available</span>
                            ) : (
                                <span className="image-disclaimer">Not all configurations are displayed in the image.</span>
                            )}
                        </div>
                    )}

                <div className="thumbsContainer">
                    {spinThumbnail &&
                        <a className={(view === 'Spinner') ? 'mainImageThumbLink active' : 'mainImageThumbLink'} onClick={() => (setView('Spinner'))}><img src={spinThumbnail} alt="360 Spinning View" /></a>
                    }
                    {Object.keys(product.studios).map(studio => (
                        <a key={'mainImageThumbLink-' + studio} className={(view === studio) ? 'mainImageThumbLink active' : 'mainImageThumbLink'} onClick={() => (setView(studio))}><img src={product.studios[studio].icon} alt={studio} /></a>
                    ))}
                </div>
                {view !== 'Spinner' && imageErrors.length > 0 && (
                    <button className="show-image-errors" onClick={showImageErrors}><i className="bi bi-info-circle"></i></button>
                )}
                </div>

                <AddOnImages lpSelectedAddOns={lpAddOns} origTractor={product} />
            </>
        );
    }

    if (Object.keys(product.studios).length == 0 && spinThumbnail == '') {
        return (
            <div id="build_details_container_main_image" className="mainImageContainer active">
                {product.equipmentHtml &&
                    <button className="imageSpecsButton" onClick={showSpecs}><em className="bi bi-card-checklist"></em> View Specs</button>
                }
                {(product.Tractor.ImagePath.length > 0) ? (
                    <div className="subImageContainer">
                        <img src={product.Tractor.ImagePath} className="active" />
                        <span className="image-disclaimer">Not all configurations are displayed in the image.</span>
                    </div>   
                ) : (
                    <span>Configurator Image Content is Not Available</span>
                )}
                <button className="show-image-errors" onClick={showImageErrors}><i className="bi bi-info-circle"></i></button>
            </div>
        );
    }

    return (
        <div id="build_details_container_main_image" className="mainImageContainer active">
            {product.equipmentHtml &&
                <button className="imageSpecsButton" onClick={showSpecs}><em className="bi bi-card-checklist"></em> View Specs</button>
            }
            {(view === 'Spinner') ? (
                <div className="subImageContainer active">
                    <div style={{ maxWidth: '3200px', maxHeight: '2560px' }}>
                        <div style={{ left: '0px', width: '100%', height: '0px', paddingBottom: '80%', overflow: 'hidden' }}>
                            <iframe src={spinSrc} allowFullScreen={true} style={{ position: 'absolute', top: '0px', left: '0px', height: '100%', width: '1px', minWidth: '100%', width: '100%' }} frameBorder="0" scrolling="no">
                            </iframe>
                        </div>
                    </div>
                    <span className="image-disclaimer">Not all configurations are displayed in the image.</span>
                </div>
            ) : ( 
                    <div className={(overlayComingSoon) ? "coming-soon subImageContainer" : "subImageContainer"}>
                        <img src={currentImageUrl} className="active" />
                        {overlayComingSoon ? (
                            <span className="noaddons coming">Image Not Available</span>
                        ) : (
                            <span className="image-disclaimer">Not all configurations are displayed in the image.</span>
                        )}
                    </div>     
            )}

            <div className="thumbsContainer">
                {spinThumbnail &&
                    <a className={(view === 'Spinner') ? 'mainImageThumbLink active' : 'mainImageThumbLink'} onClick={() => (setView('Spinner'))}><img src={spinThumbnail} alt="360 Spinning View" /></a>
                }
                {Object.keys(product.studios).map(studio => (
                    <a key={'mainImageThumbLink-' + studio} className={(view === studio) ? 'mainImageThumbLink active' : 'mainImageThumbLink'} onClick={() => (setView(studio))}><img src={product.studios[studio].icon} alt={studio} /></a>
                ))}
            </div>
            {view !== 'Spinner' && imageErrors.length > 0 && (
                <button className="show-image-errors" onClick={showImageErrors}><i className="bi bi-info-circle"></i></button>
            )}
        </div>
    );
}

function AddOnImages({ lpSelectedAddOns, origTractor }) {
    const [lpSummarySpin, setLpSummarySpin] = useState("");

    const getLandPrideSpinUrl = (addOnItem) => {
        let tempSpinUrl = 'unknown';

        console.log(addOnItem);
        console.log(addOnItem);

        origTractor.AttachHierarchy.LandPrideGroup.OptionGroupList.map(group => (
            tempSpinUrl = (group.AttachmentList.filter(a => a.Id == addOnItem.Id).length > 0) ? group.AttachmentList.filter(a => a.Id == addOnItem.Id)[0].RotatingImage.values[0] : tempSpinUrl
        ));

        return tempSpinUrl;
    }

    if ((!lpSummarySpin || lpSummarySpin == null || lpSummarySpin.length <= 0) && lpSelectedAddOns.length > 0) {
        console.log('lpSelectedAddOns');
        console.log(lpSelectedAddOns[0]);
        setLpSummarySpin(getLandPrideSpinUrl(lpSelectedAddOns[0]));
    }

    return (
        <div id="build_details_container_add-on_images" className="addOnImageContainer addOnsContent summary active">
            {(lpSelectedAddOns && lpSelectedAddOns != null && lpSelectedAddOns.length > 0) ? (
                <>
                    <div className="thumbsContainer">
                        {lpSelectedAddOns.map((item) => (
                            <a key={'mainImageThumbLink-' + item.Id} className="mainImageThumbLink" onClick={() => setLpSummarySpin(getLandPrideSpinUrl(item))}>
                                <img 
                                    src={(item.ImagePath != undefined && item.ImagePath != null && item.ImagePath.length) ? item.ImagePath : (item.PrimaryProductImage != undefined && item.PrimaryProductImage.images['300px_uri'] != null) ? item.PrimaryProductImage.images['300px_uri'] : ''}
                                    alt="360 Spinning View of Add On" /></a>
                        ))}
                    </div>

                    <div className="summaryAddOnSpinContainer">
                        <div style={{ maxWidth: '3200px', maxHeight: '2560px' }}>
                            <div style={{ left: '0px', width: '100%', height: '0px', position: 'relative', paddingBottom: '80%', overflow: 'hidden' }}>
                                <iframe src={lpSummarySpin} allowFullScreen={true} style={{ position: 'absolute', top: '0px', left: '0px', height: '100%', width: '1px', minWidth: '100%' }} frameBorder="0" scrolling="no">
                                </iframe>
                            </div >
                        </div>
                    </div>
                </>
            ) : (<span className="noaddons">No Land Pride Add-Ons Were Selected for this Build</span>)}
        </div>
    );
}